import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function DadesUsuariAfegeix(props) {
    const [nVacacions, setNVacacions] = useState('');
    const [nAP, setNAP] = useState('');
    const [grup, setGrup] = useState('');
    const [parque, setParque] = useState('');
    const [rol, setRol] = useState('');
    const [error, setError] = useState('');
    const [usuaris, setUsuaris] = useState([]);
    const [usuariIdSeleccionat, setUsuariIdSeleccionat] = useState('');
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        fetchUsuaris();
    }, []);

    const fetchUsuaris = async () => {
        try {
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/usuaris', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUsuaris(data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const guardaDadesUsuari = () => {
        // Verifica que cap camp estigui buit
        if (nVacacions.trim() === '' || nAP.trim() === '' || parque.trim() === '' || rol.trim() === '') {
            setError('Els camps no poden estar buits.');
            return;
        }

        // Envia la sol·licitud al servidor
        fetch('https://aeroguardias.es/aeroguardiasapi/public/api/dades', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                usuari_id: usuariIdSeleccionat,
                n_vacacions: nVacacions,
                n_ap: nAP,
                grup: grup,
                parque: parque,
                rol: rol
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en guardar les dades de l\'usuari.');
            } else {
                setError('');
                navigate('/dadesusuari');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Error en guardar les dades de l\'usuari.');
        });
    };

    return (
        <main>
            <hr />
            <h1>Afegir Dades d'Usuari</h1>
            <hr />
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Usuari:</Form.Label>
                    <Form.Select
                        value={usuariIdSeleccionat}
                        onChange={(e) => setUsuariIdSeleccionat(e.target.value)}
                    >
                        <option value="">Selecciona un usuari</option>
                        {usuaris && usuaris.map((usuari) => (
                            <option key={usuari.id} value={usuari.id}>{usuari.nom}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Número de Vacances:</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Introdueix el número de vacances"
                        value={nVacacions}
                        onChange={(e) => setNVacacions(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Número d'AP:</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Introdueix el número d'AP"
                        value={nAP}
                        onChange={(e) => setNAP(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Grup:</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Introdueix el grup"
                        value={grup}
                        onChange={(e) => setGrup(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Parc:</Form.Label>
                    <Form.Select
                        value={parque}
                        onChange={(e) => setParque(e.target.value)}
                    >
                        <option value="">Selecciona un parc:</option>
                        <option value="nord">Nord</option>
                        <option value="sud">Sud</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Rol:</Form.Label>
                    <Form.Select
                        value={rol}
                        onChange={(e) => setRol(e.target.value)}
                    >
                        <option value="">Selecciona el rol</option>
                        <option value="bombero">Bombero</option>
                        <option value="capataz">Capataz</option>
                    </Form.Select>
                </Form.Group>
                <div className="botons-edicio">
                    <Button variant="primary" type="button" onClick={guardaDadesUsuari}>
                        Guardar
                    </Button>
                    <Button variant="warning" type="button" onClick={() => navigate('/dadesusuari')}>
                        Cancel·lar
                    </Button>
                </div>
            </Form>
            <br />
            {error !== '' && <Alert variant="danger">{error}</Alert>}
        </main>
    );
}
