import React, { useState, useEffect } from 'react';
import { Modal, Button, Alert, Card, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ModalContacte({ isOpen, onClose }) {
    const [showModal, setShowModal] = useState(isOpen);
    const [error, setError] = useState(null);
    const [confirmmissatge, setConfirmMissatge] = useState(null);
    const [descarregant, setDescarregant] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);  // Estado para el valor del CAPTCHA

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setShowModal(false);
        if (onClose) onClose();
        setError(null);
        setConfirmMissatge(null);
    };

    const handleCaptchaChange = value => {
        setCaptchaValue(value);  // Guarda el valor del CAPTCHA
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setDescarregant(true);

        try {
            // Verificar si el reCAPTCHA ha sido resuelto
            if (!captchaValue) {
                throw new Error("Es requereix marcar el reCAPTCHA.");
            }

            const formData = {
                name: event.target.elements[0].value,
                surname: event.target.elements[1].value,
                email: event.target.elements[2].value,
                tipus: event.target.elements[3].value,
                subject: event.target.elements[4].value,
                message: event.target.elements[5].value,
            };

            // Envía los datos al backend de Laravel
            const response = await fetch("https://aeroguardias.es/aeroguardiasapi/public/api/correu", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error("Error al enviar el missatge");
            }

            const data = await response.json();
            setError(null);
            setConfirmMissatge(data.message, "Missatge enviat correctament!");
        } catch (error) {
            setError(error.message);
        } finally {
            setDescarregant(false);
        }
    };

    const modalStyle = {
        backgroundColor: 'whitesmoke',
        padding: '20px',
        maxWidth: '500px',
        width: '100%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    };

    const textAreaStyle = {
        minHeight: '150px',
        resize: 'vertical'
    };

    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Card style={modalStyle}>
                <Card.Header style={{ textAlign: 'center', backgroundColor:'#c42020', color:'white', border:'px solid black'}}>
                    <Card.Title><h4>Formulari de contacte</h4></Card.Title>
                </Card.Header>
                <Card.Body>
                    <form id='form-contacte' onSubmit={handleSubmit} style={formStyle}>
                        <div className="form-group">
                            <label htmlFor="nom">Nom:<text>*</text></label>
                            <input name='nom' type="text" className="form-control" placeholder="Nom" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="llinatges">Llinatges:<text>*</text></label>
                            <input name='llinatges' type="text" className="form-control" placeholder="Llinatges" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="correu">Correu electrònic (Al que rebreu la resposta):<text>*</text></label>
                            <input name='correu' type="email" className="form-control" placeholder="correu@gmail.com" required />
                        </div>
                        <div class="form-group">
                            <label htmlFor="tipus">Tipus d'incidència:<text>*</text></label>
                            <select name='tipus' class="form-control" required>
                                <option value="">Selecciona el tipus d'incidència:</option>
                                <option value="problema">Problema</option>
                                <option value="consulta">Consulta</option>
                                <option value="suggeriment">Suggeriment</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="assumpte">Assumpte:</label>
                            <input name='assumpte' type="text" class="form-control" placeholder="Assumpte" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="missatge">Missatge<text>*</text></label>
                            <textarea name='missatge' className="form-control" placeholder="Escriu el teu missatge aquí" required style={textAreaStyle}></textarea>
                        </div>
                        <div className='captcha'>
                            <ReCAPTCHA
                            // Captcha localhoost
                            // sitekey="6LfcDNcpAAAAAA_RZtq_2PffbDAx64IcRDPgm8O5" 
                            // Captcha aeroguardias
                            // sitekey="6LdThtYpAAAAAOWlUw4Il5P08TCQWilL4dagEghV" 
                            sitekey="6LcCc9wpAAAAAJ7txXxNj06ORdSdwN6EaoWW2kI6" 
                            onChange={handleCaptchaChange}
                            className="recaptcha"
                            />
                        </div>
                        <Button variant="primary" type="submit" style={{ padding: '10px 20px' }}>
                            {descarregant ? <Spinner animation="border" size="sm" /> : 'Enviar'}
                        </Button>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {confirmmissatge && <Alert variant="success">{confirmmissatge}</Alert>}
                    </form>
                </Card.Body>
                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" onClick={handleClose} style={{ marginLeft: 'auto' }}>Tanca</Button>
                </Card.Footer>
            </Card>
        </Modal>
    );
}