import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';

export default function UsuariDades(props) {
    const [llinatges, setLlinatges] = useState('');
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [contrasenya, setContrasenya] = useState('');
    const [Confirmarcontrasenya, setConfirmarcontrasenya] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [mostrarMissatgeconf, setMostrarmissatgeconf] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const id = props.usuari_id;
    const token = props.api_token;
    const [mostrarContrasenya, setMostrarContrasenya] = useState(false);
    const [mostraConfcontrasenya, setMostraConfcontrasenya] = useState(false);
    
    const handleToggleContrasenya = () => {
        setMostrarContrasenya(!mostrarContrasenya);
    };

    const handleToggleMostrarContrasenya = () => {
        setMostraConfcontrasenya(!mostraConfcontrasenya);
    }

    useEffect(() => {
        if (error !== '') {
            setShowError(true); // Muestra el alerta de error
            const timer = setTimeout(() => {
                setShowError(false); // Oculta el alerta después de 4 segundos
                setError(''); // Limpia el mensaje de error
            }, 4000);
            return () => clearTimeout(timer); // Limpia el timer si el componente se desmonta o si error cambia antes de que pase el tiempo
        }

        if (confirmationMessage !== '') {
            setMostrarmissatgeconf(true); // Muestra el mensaje de confirmación
            const timer = setTimeout(() => {
                setMostrarmissatgeconf(false); // Oculta el mensaje de confirmación después de 4 segundos
                setConfirmationMessage(''); // Limpia el mensaje de confirmación después de 4 segundos
            }, 4000);
            return () => clearTimeout(timer); // Limpia el timer si el componente se desmonta o si confirmationMessage cambia antes de que pase el tiempo
        }

    }, [error, confirmationMessage]); // Dependencia al estado 'error'

    useEffect(() => {
        let isMounted = true; // Flag para verificar montaje del componente

        const fetchDadesUsuari = async () => {
            if (!props.usuari_id) return; // Prevenir fetch si no hay ID
            setLoading(true);
            try {
                const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/${props.usuari_id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${props.api_token}`
                    }
                });
                if (!isMounted) return; // Prevenir actualización de estado si el componente está desmontado
                const data = await response.json();
                if (response.ok) {
                    setLlinatges(data.data.llinatges);
                    setNom(data.data.nom);
                    setEmail(data.data.correu);
                } else {
                    throw new Error(data.message || 'Error al descarregar les dades');
                }
            } catch (error) {
                if (isMounted) {
                    setError('Error al descarregar les dades de l\'usuari');
                    console.error('Error en descarregar les dades de l\'usuari:', error);
                }
            }
            if (isMounted) setLoading(false);
        };

        fetchDadesUsuari();
        return () => {
            isMounted = false; // Cambiar el flag al desmontar
        };
    }, [props.usuari_id, props.api_token]); // Dependencias actualizadas para re-fetch

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if(contrasenya==='' || contrasenya===null){
                setError('');
                setConfirmationMessage('Dades personals actualitzades, no heu cambiat la contrasenya');
                setLoading(false);
            }
            else{
                if(contrasenya!=Confirmarcontrasenya) { 
                    setConfirmationMessage('');
                    setError('Les contrasenyes no coincideixen');
                    setLoading(false);
                    return;
                }
                if(contrasenya.length<=6){
                    setConfirmationMessage('');
                    setError('La contrasenya ha de tenir com a mínim 6 caràcters');
                    setLoading(false);
                    return;
                }
                else{
                    const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/${id}`, {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            contrasenya: contrasenya
                        })
                    });
                    const responseData = await response.json();
                    if (response.ok) {
                        setError('');
                        setConfirmationMessage('Dades personals actualitzades! La propera vegada que inicieu sessió veureu les dades actualitzades.');
                    } else {
                        setError(responseData.message || 'Error al actualitzar les dades personals');
                    }
                }
            }
        } catch (error) {
            setError('Error al actualitzar les dades personals');
            console.error('Error al actualitzar les dades personals:', error);
        }
        setLoading(false);
    };

    return (
        <div className='form-dades-usuari'>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Nom i llinatges:</Form.Label>
                    <Form.Control
                        type="text"
                        value={nom}
                        readOnly
                        disabled
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Correu:</Form.Label>
                    <Form.Control
                        type="text"
                        value={email}
                        readOnly
                        disabled
                    />
                </Form.Group>
                <Form.Group className='mb-3'>
                <Form.Label>Nova Contrasenya:</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        type={mostrarContrasenya ? "text" : "password"}
                        placeholder='Contrasenya'
                        value={contrasenya}
                        onChange={(e) => setContrasenya(e.target.value)}
                    />
                    <Button
                        id='mostrarContrasenya-boto'
                        variant="secondary"
                        className="ms-2 d-inline-block"
                        onClick={handleToggleContrasenya}
                    >
                        {mostrarContrasenya ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                            </svg>  : 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                            </svg>
                        }
                    </Button>
                </div>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>Confirmar Contrasenya:</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        type={mostraConfcontrasenya ? "text" : "password"}
                        placeholder='Contrasenya'
                        value={Confirmarcontrasenya}
                        onChange={(e) => setConfirmarcontrasenya(e.target.value)}
                    />
                    <Button
                        id='mostrarContrasenya-boto'
                        variant="secondary"
                        className="ms-2 d-inline-block"
                        onClick={handleToggleMostrarContrasenya}
                    >
                        {mostraConfcontrasenya ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                            </svg>  : 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                            </svg>
                        }
                    </Button>
                </div>
            </Form.Group>
            <div className="botons-edicio">
                <Button type="submit" disabled={loading}>
                    Guardar
                </Button>
                <Button variant="warning" onClick={() => { window.location.reload(); }}>
                    Cancel·la
                </Button>
                <Button variant="info" onClick={() => {
                   props.setShowForm(false);
                }}>
                    Ocultar
                </Button>
            </div>
            </Form>
            {loading && 
            <div className="spinner">
                <Spinner animation="border" />
            </div>}
            <br />
            {mostrarMissatgeconf && (
                <div className="alert alert-success" role="alert">
                    <p>{confirmationMessage}</p>
                </div>
            )}
            {showError && <Alert variant="danger">{error}</Alert>}
        </div>
    );
}
