import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const CondicionsUs = () => {
  return (
    <main>
    <Container className="mt-4">
      <Row>
        <Col md={12}>
            <div className='pol-cond'>
                <div className='pol-cond-titol'>
                    <h2>Condicions d'Ús d'AeroGuardias</h2>
                </div>
                <p className="mt-4">
                    Benvingut a AeroGuardias. Les següents condicions regulen l'ús de la nostra plataforma de gestió d'horaris per als bombers d'aeroports d'Espanya i altres funcionalitats associades.
                </p>
                <h3>1. Accés i Utilització de la Plataforma</h3>
                <p>
                    L'accés i ús de la plataforma AeroGuardias està reservat als bombers i el seu personal administratiu. Els usuaris es comprometen a utilitzar la plataforma de manera responsable i conforme a la llei i aquestes condicions.
                </p>
                <h3>2. Propietat Intel·lectual</h3>
                <p>
                    El contingut, disseny i funcionalitats de la plataforma AeroGuardias són propietat exclusiva de l'entitat gestora i estan protegits per les lleis de propietat intel·lectual i industrial.
                </p>
                <h3>3. Responsabilitat de l'Usuari</h3>
                <p>
                    Els usuaris són responsables de la veracitat de les dades que introdueixen a la plataforma i del correcte ús de les informacions i funcionalitats que AeroGuardias ofereix.
                </p>
                <p>
                    Els usuaris són responsables de mantenir la confidencialitat de les seves dades d'accés i de notificar qualsevol ús no autoritzat del seu compte.
                </p>
                <p>
                    <b style={{fontFamily: 'RobotoMedium'}}>*AeroGuardias no es fa responsable de la pèrdua de dades o informacions introduïdes pels usuaris a la plataforma, així com de la dependència d'us per part dels usuaris per dur a terme les funcions de cada bomber. És recomanable consultar sempre l'horari vigent i oficial del seu Aeroport o empresa de gestió.</b>
                </p>
                <h3>4. Modificacions del Servei</h3>
                <p>
                    AeroGuardias es reserva el dret de modificar o cancel·lar qualsevol funcionalitat o aspecte de la plataforma sense avís previ.
                </p>
                <h3>5. Limitació de Responsabilitat</h3>
                <p>
                   AeroGuardias no serà responsable de danys o pèrdues derivats de l'ús o incapacitat d'ús de la plataforma, incloent interrupcions de servei, errors o altres.
                </p>
                <h3>6. Acceptació de les Condicions</h3>
                <p>
                    L'ús de la plataforma AeroGuardias suposa la completa acceptació de les presents condicions d'ús.
                </p>
                <h3>7. Legislació i Jurisdicció Aplicable</h3>
                <p>
                    Qualsevol controvèrsia derivada de l'ús de la plataforma serà sotmesa a la legislació espanyola i als jutjats i tribunals de la ciutat de Palma.
                </p>
            </div>
        </Col>
      </Row>
    </Container>
    </main>
  );
};

export default CondicionsUs;
