import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PoliticaPrivacitat = () => {
  return (
    <main>
    <Container className="mt-4">
      <Row>
        <Col md={12}>
            <div className='pol-cond'>
                <div className='pol-cond-titol'>
                    <h2>Política de Privacitat de AeroGuardias</h2>
                </div>
                <p>
                    A AeroGuardias, ens comprometem a protegir la privacitat i a proporcionar una experiència segura als nostres usuaris. Aquesta política de privacitat descriu com recopilem, utilitzem i compartim la informació personal dels bombers dels aeroports en l'ús de la nostra plataforma centralitzada de gestió d'horaris.
                </p>
                <h3>1. Recopilació de la Informació</h3>
                <p>
                    Recollim informació que ens proporcionen els usuaris al registrar-se a la plataforma, com ara nom, adreça de correu electrònic i detalls del contacte professional. A més, recopilem dades sobre els torns, activitats assignades, gestió de vacances i altres comunicacions internes.
                </p>
                <h3>2. Ús de la Informació</h3>
                <p>
                    La informació recollida s'utilitza per facilitar la planificació i gestió dels horaris dels bombers, així com per millorar la claredat de les funcions assignades. També utilitzem aquestes dades per oferir funcionalitats addicionals com la gestió de vacances i la comunicació interna.
                </p>
                <h3>3. Compartició de la Informació</h3>
                <p>
                    No compartim la informació personal amb tercers excepte quan és necessari per a l'operativa de la nostra plataforma o quan la llei ens obliga a fer-ho. Les dades poden ser accessibles per l'administrador de la plataforma per a la gestió i resolució de problemes.
                </p>
                <h3>4. Seguretat</h3>
                <p>
                    Prendrem totes les mesures necessàries per assegurar que les seves dades estan segures. Utilitzem protocols de seguretat estàndards i treballem només amb proveïdors de serveis de confiança.
                </p>
                <h3>5. Canvis en la Política de Privacitat</h3>
                <p>
                    AeroGuardias es reserva el dret de modificar aquesta política de privacitat en qualsevol moment. Qualsevol canvi serà publicat en aquesta pàgina i, si els canvis són significatius, proporcionarem un avís més destacat.
                </p>
                <h3>6. Contacte</h3>
                <p>
                    Si té qualsevol pregunta o preocupació sobre la nostra política de privacitat o les seves dades, si us plau contacti amb nosaltres a través del formulari de contacte de la nostra web.
                </p>
            </div>
        </Col>
      </Row>
    </Container>
    </main>
  );
};

export default PoliticaPrivacitat;
