import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Table, Modal } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { storage } from "../../utils/storage";

const TornsExtres = ({ token, parqueUsuario, usuari_id, usuari_nom }) => {
   const [date, setDate] = useState("");
   const [showForm, setShowForm] = useState(false);
   const [bomberos, setBomberos] = useState([]);
   const [selectedBombero, setSelectedBombero] = useState("");
   const [successMessage, setSuccessMessage] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [extraTurnos, setExtraTurnos] = useState([]);
   const [showCreateModal, setShowCreateModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [turnoToDelete, setTurnoToDelete] = useState(null);

   useEffect(() => {
      const fetchBomberos = async () => {
         if (date) {
            try {
               const response = await axios.get(
                  `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/data/${moment(date).format(
                     "YYYY-MM-DD"
                  )}/${parqueUsuario}`,
                  {
                     headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                     },
                  }
               );

               if (response.data.status === "success" && response.data.data) {
                  const bomberosTurno = response.data.data.filter(
                     (turno) => turno.tipus === "D" || turno.tipus === "C"
                  );
                  setBomberos(bomberosTurno);
               } else {
                  setBomberos([]);
               }
            } catch (error) {
               console.error("Error fetching bomberos:", error);
               setBomberos([]);
            }
         } else {
            setBomberos([]);
         }
      };

      fetchBomberos();
   }, [date, token, parqueUsuario]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      setSuccessMessage("");
      setErrorMessage("");
      setShowCreateModal(false);

      try {
         const response = await axios.post(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/extra`,
            {
               turno_id: selectedBombero,
               usuari_id: usuari_id,
            },
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );
         console.log("Response:", response.data);

         setSuccessMessage("El torn extra s'ha guardat correctament.");
         recarregarCalendari();
      } catch (error) {
         console.error("Error saving turn:", error);
         setErrorMessage("S'ha produït un error en guardar el torn extra.");
      }
   };

   const recarregarCalendari = () => {
      console.log("Recarregant calendari...");
      localStorage.removeItem("events");
      localStorage.removeItem("notesCalendari");
      window.location.reload();
   };

   const fetchExtraTurnos = async () => {
      try {
         const response = await axios.get(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/extra/${usuari_id}`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.data.status === "success" && response.data.data) {
            setExtraTurnos(response.data.data);
         } else {
            setExtraTurnos([]);
         }
      } catch (error) {
         console.error("Error fetching extra turnos:", error);
         setExtraTurnos([]);
      }
   };

   const deleteExtraAndUpdateTurno = async () => {
      try {
         const response = await axios.post(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/delete-extra-and-update`,
            {
               turno_id1: turnoToDelete.id,
               turno_id2: turnoToDelete.turno_id2,
            },
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.data.status === "success") {
            setSuccessMessage(
               "El turno extra ha sido eliminado y el turno actualizado correctamente."
            );
            // Eliminar events i recarregar la pagina
            storage.remove("events");
            window.location.reload();
            fetchExtraTurnos();
         } else {
            setErrorMessage(
               "Error al eliminar el turno extra y actualizar el turno."
            );
         }
         setShowDeleteModal(false);
      } catch (error) {
         console.error("Error deleting extra turno and updating turno:", error);
         setErrorMessage(
            "Error al eliminar el turno extra y actualizar el turno."
         );
      }
   };

   useEffect(() => {
      fetchExtraTurnos();
   }, [usuari_id, token]);

   return (
      <div>
         <div className="consultar-turnos">
            <div className="consultar-turnos-title">
               <h1>Afegir COS 🚒 </h1>
            </div>
            <div className="consultar-turnos-boto">
               <Button
                  className="botoNormal-notes"
                  variant={showForm ? "danger" : "warning"}
                  type="button"
                  onClick={() => setShowForm(!showForm)}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     fill="currentColor"
                     className="bi bi-plus-circle"
                     viewBox="0 0 16 16"
                  >
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                     <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  {showForm ? "Cancelar" : "Afegir torns extres"}
               </Button>
            </div>
         </div>
         {showForm && (
            <>
               <hr />
               {successMessage && (
                  <Alert variant="success">{successMessage}</Alert>
               )}
               {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
               <div className="form-cercar-turnos-container">
                  <Form
                     className="form-cercar-turnos"
                     onSubmit={(e) => {
                        e.preventDefault();
                        setShowCreateModal(true);
                     }}
                  >
                     <div className="camps-cercar-dia">
                        <Form.Label>Indica el dia del torn extra:</Form.Label>
                        <Form.Control
                           type="date"
                           value={date}
                           onChange={(e) => {
                              setDate(e.target.value);
                              setSelectedBombero(""); // Reset the selected bombero when the date changes
                           }}
                        />
                        <br />
                        <Form.Label>
                           Indica el Bomber al que substitueixes:
                        </Form.Label>
                        <Form.Control
                           as="select"
                           value={selectedBombero}
                           onChange={(e) => setSelectedBombero(e.target.value)}
                        >
                           <option value="">Sel·lecciona un bomber</option>
                           {bomberos.map((bombero) => (
                              <option
                                 key={bombero.usuari_id}
                                 value={bombero.id}
                              >
                                 {bombero.nom}
                              </option>
                           ))}
                        </Form.Control>
                     </div>
                     <div className="botons-cercar-dia">
                        <Button variant="primary" type="submit">
                           Guardar
                        </Button>
                     </div>
                  </Form>
               </div>
            </>
         )}
         <div>
            {extraTurnos.length > 0 ? (
               <table className="turnos-extra">
                  <thead>
                     <tr>
                        <th colSpan={3}>Turnos Extra</th>
                     </tr>
                     <tr>
                        <td>Data</td>
                        <td>Company de canvi</td>
                        <td>Esborrar</td>
                     </tr>
                  </thead>
                  <tbody>
                     {extraTurnos.map((turno, index) => (
                        <tr key={index}>
                           <td>{turno.data}</td>
                           <td>{turno.nom}</td>
                           <td>
                              <button
                                 id="esborrar-extra"
                                 onClick={() => {
                                    setTurnoToDelete(turno);
                                    setShowDeleteModal(true);
                                 }}
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-trash3"
                                    viewBox="0 0 16 16"
                                 >
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                 </svg>
                              </button>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            ) : (
               <>
                  <hr />
                  <div id="infonotes" className="missatge-notes">
                     <p>No tens cap torn extra 💪</p>
                  </div>
               </>
            )}
         </div>

         {/* Create Turn Modal */}
         <Modal
            show={showCreateModal}
            onHide={() => setShowCreateModal(false)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title>Confirmar Creació del Torn Extra</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <p>Segur que voleu crear aquest Torn Extra?</p>
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
               >
                  Cancelar
               </Button>
               <Button variant="primary" onClick={handleSubmit}>
                  Confirmar
               </Button>
            </Modal.Footer>
         </Modal>

         {/* Delete Turn Modal */}
         <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title>Confirmar Eliminació del Torn Extra</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Alert variant="danger">
                  <div className="logout-info">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle"
                        viewBox="0 0 16 16"
                     >
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                     </svg>
                     <div>Segur que voleu eliminar aquest Torn Extra?</div>
                  </div>
               </Alert>
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => setShowDeleteModal(false)}
               >
                  Cancelar
               </Button>
               <Button variant="danger" onClick={deleteExtraAndUpdateTurno}>
                  Confirmar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default TornsExtres;
