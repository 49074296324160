import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export default function DadesUsuariCRUD(props) {
    const [nVacacions, setNVacacions] = useState('');
    const [nAP, setNAP] = useState('');
    const [grup, setGrup] = useState('');
    const [parque, setParque] = useState('');
    const [rol, setRol] = useState('');
    const [usuariId, setUsuariId] = useState('');
    const [usuaris, setUsuaris] = useState([]);
    const [error, setError] = useState('');
    const [descarregant, setDescarregant] = useState(false);
    const [edita, setEdita] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        if (id !== "-1") {
            descarregaDadesUsuari();
        } else {
            setEdita(false);
        }
        descarregaUsuaris();
    }, [id]);

    const descarregaDadesUsuari = async () => {
        setDescarregant(true);
        setEdita(true);
        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/${id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            const { n_vacacions, n_ap, grup, parque, rol, usuari_id } = responseData.data;
            setNVacacions(n_vacacions);
            setNAP(n_ap);
            setGrup(grup);
            setParque(parque);
            setRol(rol);
            setUsuariId(usuari_id);
        } catch (error) {
            console.log(error);
        }
        setDescarregant(false);
    }

    const descarregaUsuaris = async () => {
        try {
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/usuaris', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUsuaris(data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const guardaDadesUsuari = () => {
        // Verifica que cap camp estigui buit
        if (!usuariId || !parque || !rol) {
            setError('Els camps no poden estar buits.');
            return;
        }

        // Envia la sol·licitud al servidor
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                usuari_id: usuariId,
                n_vacacions: nVacacions,
                n_ap: nAP,
                grup: grup,
                parque: parque,
                rol: rol
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en guardar les dades de l\'usuari.');
            } else {
                setError('');
                navigate('/dadesusuari');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Error en guardar les dades de l\'usuari.');
        });
    };

    const esborraDadesUsuari = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en esborrar les dades de l\'usuari.');
            } else {
                setError('');
                navigate('/dadesusuari');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Error en esborrar les dades de l\'usuari.');
        });
    }

    if (descarregant) {
        return (<main>
            <div className='spinner'>
                <Spinner/>
            </div>
        </main>);
    }

    return (
        <main>
            <hr />
            <h1>Editar Dades d'Usuari</h1>
            <hr />
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Usuari:</Form.Label>
                    <Form.Select
                        value={usuariId}
                        onChange={(e) => setUsuariId(e.target.value)}
                    >
                        {usuaris && usuaris.map((usuari) => (
                            <option key={usuari.id} value={usuari.id} defaultValue={usuari.id === usuariId}>
                                {usuari.nom}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Número de Vacances:</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Introdueix el número de vacances"
                        value={nVacacions}
                        onChange={(e) => setNVacacions(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Número d'AP:</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Introdueix el número d'AP"
                        value={nAP}
                        onChange={(e) => setNAP(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Grup:</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Introdueix el grup"
                        value={grup}
                        onChange={(e) => setGrup(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Parc:</Form.Label>
                    <Form.Select
                        value={parque}
                        onChange={(e) => setParque(e.target.value)}
                    >
                        <option value="">Selecciona un parc:</option>
                        <option value="nord">Nord</option>
                        <option value="sud">Sud</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Rol:</Form.Label>
                    <Form.Select
                        value={rol}
                        onChange={(e) => setRol(e.target.value)}
                    >
                        <option value="">Selecciona un rol:</option>
                        <option value="bombero" selected={rol === 'bombero'}>Bombero</option>
                        <option value="capataz" selected={rol === 'capataz'}>Capataz</option>
                    </Form.Select>
                </Form.Group>
                <div className="botons-edicio">
                    <Button variant="primary" type="button" onClick={guardaDadesUsuari}>Guardar</Button>
                    <Button variant="warning" type="button" onClick={() => navigate('/dadesusuari')}>Cancel·lar</Button>
                    {edita && (
                        <Button variant="danger" type="button" onClick={esborraDadesUsuari}>Esborrar</Button>
                    )}
                </div>
            </Form>
            <br />
            {error !== "" && <Alert variant="danger">{error}</Alert>}
        </main>
    );
}
