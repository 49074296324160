import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

const CercarTurno = ({ token, usuari_id }) => {
   const [date, setDate] = useState("");
   const [showForm, setShowForm] = useState(false);
   const [loading, setLoading] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [bomberos, setBomberos] = useState([]);
   const [error, setError] = useState(null);
   const [parqueUsuario, setParqueUsuario] = useState("");

   useEffect(() => {
      const fetchParqueUsuario = async () => {
         const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         };
         try {
            const response = await axios.get(
               `https://aeroguardias.es/aeroguardiasapi/public/api/dades/usuari/${usuari_id}`,
               { headers }
            );
            if (response.data.status === "success") {
               setParqueUsuario(response.data.data.parque);
            } else {
               throw new Error(
                  "No s'ha pogut obtenir la informació del parc de l'usuari."
               );
            }
         } catch (error) {
            console.error("Error al buscar el parc:", error);
            setError(error.message);
         }
      };
      fetchParqueUsuario();
   }, [token, usuari_id]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
      try {
         const response = await axios.get(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/data/${moment(date).format(
               "YYYY-MM-DD"
            )}/${parqueUsuario}`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
               },
            }
         );

         if (response.data.status === "success" && response.data.data) {
            const events = response.data.data.filter(
               (turno) => turno.tipus !== "V" && turno.tipus !== "B" 
            ); // Excluir tipos V y B
            // Excluir los que tengan turno.visible === 0
            const rolesResponse = await axios.get(
               "https://aeroguardias.es/aeroguardiasapi/public/api/lletresirols",
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            );

            const rolesData = rolesResponse.data.data;
            const letter = getLetterForDate(new Date(date));

            const bomberosConRol = events.map((turno) => {
               const roleEntry = rolesData.find(
                  (entry) =>
                     entry[letter.toLowerCase()] === parseInt(turno.grup)
               );
                  return {
                    nom: turno.visible === 0 ? <i>OCULT PER L'USUARI</i> : turno.nom,
                    rol:
                      turno.rol === "capataz"
                        ? "Capataz"
                        : roleEntry
                        ? roleEntry.rol
                        : "Rol no encontrado",
                    tipus: turno.tipus,
                  };
            });

            setBomberos(bomberosConRol);
            setShowModal(true);
         } else {
            throw new Error("No se encontraron datos.");
         }
      } catch (error) {
         setError("Error al buscar los turnos.");
         console.error(error);
      }
      setLoading(false);
   };

   const getLetterForDate = (date) => {
      const referenceDate = new Date("2024-01-01");
      const daysDifference = Math.floor(
         (date - referenceDate) / (1000 * 60 * 60 * 24)
      );
      const letters = ["B", "C", "D", "E", "F", "G", "A"];
      const letterIndex = Math.floor(daysDifference / 6) % letters.length;
      return letters[letterIndex];
   };

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const rolePriority = {
      Capataz: 0,
      "Conductor 1ª": 1,
      "Ayudante 1ª": 2,
      "Conductor 2ª": 3,
      "Ayudante 2ª": 4,
      "Ayudante 3ª": 5,
      Comodin: 6,
      "REVISAR CANVI": 7,
      FORMACION: 8,
   };

   return (
      <div>
         <div className="consultar-turnos">
            <div className="consultar-turnos-title">
               <h1>Consultar Torn 🔍</h1>
            </div>
            <div className="consultar-turnos-boto">
               <Button
                  className="botoNormal-notes"
                  variant={showForm ? "danger" : "warning"}
                  type="button"
                  onClick={() => setShowForm(!showForm)}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="16"
                     height="16"
                     fill="currentColor"
                     className="bi bi-search"
                     viewBox="0 0 16 16"
                  >
                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                  {showForm ? "Cancelar" : "Consultar torn d'un dia concret"}
               </Button>
            </div>
         </div>
         {showForm && (
            <>
               <hr />
               <div className="form-cercar-turnos-container">
                  <Form className="form-cercar-turnos" onSubmit={handleSubmit}>
                     <div className="camps-cercar-dia">
                        <Form.Label>Quin dia vols consultar?</Form.Label>
                        <Form.Control
                           type="date"
                           value={date}
                           onChange={(e) => setDate(e.target.value)}
                        />
                     </div>
                     <div className="botons-cercar-dia">
                        <Button variant="primary" type="submit">
                           {loading ? (
                              <Spinner animation="border" size="sm" />
                           ) : (
                              "Mostrar"
                           )}
                        </Button>
                     </div>
                  </Form>
               </div>
            </>
         )}

         <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  Bombers en torn - {moment(date).format("LL")}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {error ? (
                  <p className="text-danger">{error}</p>
               ) : (
                  <div className="info-turnos">
                     <div className="info-turno-companys">
                        <h5>Companys del dia seleccionat:</h5>
                     </div>
                     <div className="grup-companys-turnos">
                        <table className="companys-turnos">
                           <thead>
                              <tr>
                                 <th>NOM</th>
                                 <th>ROL</th>
                              </tr>
                           </thead>
                           <tbody>
                              {bomberos
                                 .sort((a, b) => {
                                    if (a.tipus === "T" && b.tipus !== "T")
                                       return -1;
                                    if (a.tipus !== "T" && b.tipus === "T")
                                       return 1;
                                    return (
                                       (rolePriority[a.rol] || 99) -
                                       (rolePriority[b.rol] || 99)
                                    );
                                 })
                                 .map((bombero, idx) => (
                                    <tr
                                       key={idx}
                                       className={
                                          bombero.tipus === "C"
                                             ? "cambio"
                                             : bombero.tipus === "N"
                                             ? "no-viene"
                                             : bombero.tipus === "F"
                                             ? "formacion"
                                             : "company"
                                       }
                                    >
                                       <td>
                                          {bombero.nom}
                                          {bombero.tipus === "N" && (
                                             <>
                                                <br />
                                                <h6>(HA FET CANVI)</h6>
                                             </>
                                          )}
                                       </td>
                                       <td>
                                          {bombero.tipus === "C"
                                             ? "REVISAR CANVI"
                                             : bombero.tipus === "F"
                                             ? "FORMACION"
                                             : bombero.rol}
                                       </td>
                                    </tr>
                                 ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleCloseModal}>
                  Tancar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default CercarTurno;
