import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export default function UsuarisCRUD(props) {
    const [nomUsuari, setNomUsuari] = useState("");
    const [mailUsuari, setMailUsuari] = useState("");
    const [contrasenyaUsuari, setContrasenyaUsuari] = useState("");
    const [rolUsuari, setRolUsuari] = useState("");
    const [validat, setValidat] = useState('');
    const [databaixa, setDatabaixa] = useState(''); // Cambiado el nombre de la variable
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [missatge, setMissatge] = useState('');
    const [edita, setEdita] = useState(false);
    const [descarregant, setDescarregant] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        if (id !== "-1") {
            descarregaUsuari();
        } else {
            setEdita(false);
        }
    }, [id]);

    useEffect(() => {
        if (error) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                setError('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    useEffect(() => {
        if (missatge) {
            const timer = setTimeout(() => {
                setMissatge('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [missatge]);

    const descarregaUsuari = async () => {
        setDescarregant(true);
        setEdita(true);
        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/${id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            const { nom, llinatges, correu, rol, actiu, data_baixa } = responseData.data;
            setNomUsuari(nom);
            setMailUsuari(correu);
            setRolUsuari(rol);
            setValidat(actiu);
            setDatabaixa(data_baixa); 
            console.log(data_baixa);
        } catch (error) {
            console.log(error);
        }
        setDescarregant(false);
    }

    const guardaUsuari = () => {
        if (edita) {
            if(contrasenyaUsuari!==''){
                modificaUsuariContra();
            } else {
                modificaUsuari();
            }
        } 
    }

    const modificaUsuari = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                rol: rolUsuari,
                actiu: validat
            })
        }).then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en guardar l\'usuari.'); 
            } else {
                setError('');
                navigate('/usuaris');
            }
        })
    }
     
    const modificaUsuariContra = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                contrasenya: contrasenyaUsuari,
                rol: rolUsuari,
                actiu: validat
            })
        }).then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                console.log(contrasenyaUsuari);
                if (data.data.contrasenya && data.data.contrasenya.length > 0) {
                    setError(data.data.contrasenya[0]); 
                } else {
                    setError('Error en guardar l\'usuari.'); 
                }
            } else {
                setError('');
                navigate('/usuaris');
            }
        })
    }

    const baixaUsuari = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/delete/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(response => response.json())
            .then((data) => {
                if (data.error) {
                    setError("Error al donar de baixa l'usuari.");
                } else {
                    setError('');
                    setMissatge("Usuari donat de baixa.");
                }
            })
            setTimeout(() => {
                navigate('/usuaris');
            }, 2000);
    }

    const altaUsuari = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/alta/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                data_baixa: null
            })
        }).then(response => response.json())
            .then((data) => {
                if (data.error) {
                    setError("Error al donar d'alta l'usuari.");
                } else {
                    setError('');
                    setMissatge("Usuari donat d'alta.");
                    // Temporitzador de 3 segons per redirigir a la llista d'usuaris
                    setTimeout(() => {
                        navigate('/usuaris');
                    }, 2000);
                }
            })
    }

    const esborraUsuari = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError("Error en borrar l'usuari.");
                } else {
                    navigate('/usuaris');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError("Error en borrar l'usuari.");

            });
    }

    if (descarregant) {
        return (<main>
            <div className='spinner'>
                <Spinner/>
            </div>
        </main>);
    }

    return (
        <main>
            <hr />
            <h1>Editar Usuari</h1>
            <hr />
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Nom i llinatges:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nom d'usuari"
                        value={nomUsuari}
                        disabled
                        onChange={(e) => setNomUsuari(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Correu:</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Correu de l'usuari"
                        value={mailUsuari}
                        disabled
                        onChange={(e) => setMailUsuari(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Contrasenya:</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Contrasenya de l'usuari (Si no s'introdueix no es canviarà)"
                        value={contrasenyaUsuari}
                        onChange={(e) => setContrasenyaUsuari(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Rol:</Form.Label>
                    <Form.Select
                        value={rolUsuari}
                        onChange={(e) => setRolUsuari(e.target.value)}
                    >
                        <option value="bombero">Bombero</option>
                        <option value="administrador">Administrador</option>
                        <option value="gestor">Gestor</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Actiu:</Form.Label>
                    <Form.Select
                        value={validat}
                        onChange={(e) => setValidat(parseInt(e.target.value))}
                    >
                        <option value={1}>Sí</option>
                        <option value={0}>No</option>
                    </Form.Select>
                </Form.Group>
                <div className="botons-edicio">
                    <Button variant="primary" type="button" onClick={guardaUsuari}>Guardar</Button>
                    <Button variant="warning" type="button" onClick={() => navigate("/usuaris")}>Cancel·lar</Button>
                    {edita && databaixa === null ? ( 
                        <Button variant="danger" type="button" onClick={baixaUsuari}>Donar de baixa</Button>
                    ) : (
                        <Button variant="success" type="button" onClick={altaUsuari}>Donar d'alta</Button>
                    )}
                    {edita && (
                        <Button variant="danger" type="button" onClick={esborraUsuari}>Esborrar</Button>
                    )}
                </div>
            </Form>
            <br />
            {error !== "" && <Alert variant="danger">{error}</Alert>}
            {missatge !== "" && <Alert variant="success">{missatge}</Alert>}
        </main>
    );
}
