import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function TurnosAfegeix(props) {
    const [data, setData] = useState('');
    const [activitat, setactivitat] = useState('');
    const [tipus, setTipus] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [usuaris, setUsuaris] = useState([]);
    const [usuariIdSeleccionat, setUsuariIdSeleccionat] = useState('');
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        fetchUsuaris();
    }, []);

    useEffect(() => {
        if (error) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                setError('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const fetchUsuaris = async () => {
        try {
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/usuaris', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUsuaris(data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const guardaTurno = () => {
        if (!data || !activitat || !tipus || !usuariIdSeleccionat) {
            setError('Tots els camps són obligatoris.');
            return;
        }

            // Verificación de la correspondencia entre 'tipus' y 'activitat'
        const tipusActivitat = {
            'D': 'Turno',
            'V': 'Vacaciones',
            'B': 'Baja',
            'DB': 'Baja',
            'C': 'Turno-Cambio',
            'N': 'No-Viene',
            'F': 'Formacion',
            'DF': 'A.P.'
        };

        if (activitat !== tipusActivitat[tipus]) {
            setError(`L\'activitat seleccionada no correspon al tipus ${tipus}.`);
            return;
        }

        fetch('https://aeroguardias.es/aeroguardiasapi/public/api/turnos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                data: data,
                activitat: activitat,
                tipus: tipus,
                usuari_id: usuariIdSeleccionat
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en guardar el torn.');
            } else {
                setError('');
                navigate('/turnos');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Error en guardar el torn.');
        });
    };

    return (
       <main>
          <hr />
          <h1>Afegir Torn</h1>
          <hr />
          <Form>
             <Form.Group className="mb-3">
                <Form.Label>Data:</Form.Label>
                <Form.Control
                   type="date"
                   value={data}
                   onChange={(e) => setData(e.target.value)}
                />
             </Form.Group>
             <Form.Group className="mb-3">
                <Form.Label>Tipus:</Form.Label>
                <Form.Select
                   value={tipus}
                   onChange={(e) => setTipus(e.target.value)}
                >
                   <option value="">Selecciona un tipus</option>
                   <option value="D">D</option>
                   <option value="B">B</option>
                   <option value="V">V</option>
                   <option value="DB">DB</option>
                   <option value="C">C</option>
                   <option value="N">N</option>
                   <option value="F">F</option>
                   <option value="DF">DF</option>
                </Form.Select>
             </Form.Group>
             <Form.Group className="mb-3">
                <Form.Label>Activitat:</Form.Label>
                <Form.Select
                   value={activitat}
                   onChange={(e) => setactivitat(e.target.value)}
                >
                   <option value="">Selecciona una Activitat</option>
                   <option value="Baja">Baja</option>
                   <option value="Vacaciones">Vacaciones</option>
                   <option value="Turno">Turno</option>
                   <option value="Turno-Cambio">Turno-Cambio</option>
                   <option value="No-Viene">No-Viene</option>
                   <option value="Formacion">Formacion</option>
                   <option value="A.P.">A.P.</option>
                </Form.Select>
             </Form.Group>
             <Form.Group className="mb-3">
                <Form.Label>Usuari:</Form.Label>
                <Form.Select
                   value={usuariIdSeleccionat}
                   onChange={(e) => setUsuariIdSeleccionat(e.target.value)}
                >
                   <option value="">Selecciona un usuari</option>
                   {usuaris &&
                      usuaris.map((usuari) => (
                         <option key={usuari.id} value={usuari.id}>
                            {usuari.nom}
                         </option>
                      ))}
                </Form.Select>
             </Form.Group>
             <div className="botons-edicio">
                <Button variant="primary" type="button" onClick={guardaTurno}>
                   Guardar
                </Button>
                <Button
                   variant="warning"
                   type="button"
                   onClick={() => navigate("/turnos")}
                >
                   Cancel·lar
                </Button>
             </div>
          </Form>
          <br />
          {error !== "" && <Alert variant="danger">{error}</Alert>}
       </main>
    );
}
