import React, { useState } from 'react';
import { Modal, Button, Alert, Spinner } from 'react-bootstrap';
import { storage } from '../../utils/storage';
// Navigate
import { useNavigate } from 'react-router-dom';

export default function Logout({ isOpen, onClose, usuari_id }) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const ferLogout = (event) => {
        event.preventDefault();
        setLoading(true);
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/logout/${usuari_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error en el logout');
                }
                return response.json();
            })
            .then(respostajson => {
                if (respostajson.status === "success") {
                    setError(false);
                    storage.remove('events');
                    storage.remove('notesCalendari');
                    storage.remove('_grecaptcha');
                    storage.remove('api_token');
                    window.location.href = "/login";                    
                } else {
                    setError(true);
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setError(true);
                setLoading(false);
            });
    };

    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Tancar Sessió</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    <div className='logout-info'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                        </svg>
                        <div>Segur que voleu tancar la sessió?</div> 
                    </div>
                </Alert>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel·lar
                </Button>
                <Button variant="danger" onClick={ferLogout} disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Tancar sessió'}
                </Button>
                {error && <Alert variant="danger">No s'ha pogut tancar la sessió.</Alert>}
            </Modal.Footer>
        </Modal>
    );
}
