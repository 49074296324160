import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { storage } from '../../utils/storage';
import VisibilitatTurnos from './VisibilitatTurnos';

const Lletresirolsgestor = (props) => {
   const [selectedFileTurnos, setSelectedFileTurnos] = useState(null);
   const [selectedFileDates, setSelectedFileDates] = useState(null);
    const token = props.api_token;
    const [missatgeTurnos, setmissatgeTurnos] = useState('');
   const [missatgeParques, setmissatgeParques] = useState('');
   const [missatgeDates, setmissatgeDates] = useState('');
    const [errorTurnos, seterrorTurnos] = useState('');
   const [errorParques, seterrorParques] = useState('');
   const [errorDates, seterrorDates] = useState('');
   const [isLoadingTurnos, setisLoadingTurnos] = useState(false);
   const [isLoadingDates, setisLoadingDates] = useState(false);
    const [isLoadingParques, setisLoadingParques] = useState(false);
   const [showDeleteShiftsModal, setShowDeleteShiftsModal] = useState(false);
   const [showDeleteDatesModal, setShowDeleteDatesModal] = useState(false);
    const [showChangeParkModal, setShowChangeParkModal] = useState(false); // Nuevo estado para el modal de cambio de parque
    const [showError, setShowError] = useState(false);

    // UseEffect para manejar la visibilidad de mensajes de error y confirmación
    useEffect(() => {
        if (errorTurnos || errorParques || errorDates) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                seterrorTurnos('');
               seterrorParques('');
               seterrorDates('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [errorTurnos, errorParques, errorDates]);

    useEffect(() => {
        if (missatgeTurnos || missatgeParques || missatgeDates) {
            const timer = setTimeout(() => {
                setmissatgeTurnos('');
               setmissatgeParques('');
               setmissatgeDates('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [missatgeTurnos, missatgeParques, missatgeDates]);


    // Funciones para manejar el modal de cambio de parque
    const handleShowChangeParkModal = () => setShowChangeParkModal(true);
   const handleCloseChangeParkModal = () => setShowChangeParkModal(false);

    // Función para iniciar el cambio de parque con confirmación
    const handleConfirmChangePark = async () => {
        handleCloseChangeParkModal();
        await canviparques(); // Llama a tu función de cambio de parque después de la confirmación
    };

    const handleFileChangeTurnos = (event) => {
        setSelectedFileTurnos(event.target.files[0]);
    };
   
   const handleFileChangeDates = (event) => {
      setSelectedFileDates(event.target.files[0]);
   };

    const handleShowDeleteShiftsModal = () => setShowDeleteShiftsModal(true);
   const handleCloseDeleteShiftsModal = () => setShowDeleteShiftsModal(false);
      const handleShowDeleteDatesModal = () => setShowDeleteDatesModal(true);
   const handleCloseDeleteDatesModal = () => setShowDeleteDatesModal(false);

   // Funció per pujar les dates
   const pujarDates = async () => {
      if (!selectedFileDates) {
         console.error('Ningún arxiu seleccionat');
         return;
      }

      const formData = new FormData();
      formData.append('excel', selectedFileDates);

      try {
         setisLoadingDates(true);
         const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/excel/dates', {
            method: 'POST',
            headers: {
               'Authorization': `Bearer ${token}`,
            },
            body: formData,
         });
         const data = await response.json();
         if (data.status === 'success') {
            seterrorDates('');
            storage.remove('events');
            setmissatgeDates(data.message);
         } else {
            setmissatgeDates('');
            seterrorDates('Error al importar l\'excel');
         }
      } catch (error) {
         console.error('Error:', error);
         seterrorDates('Error al importar l\'excel');
      } finally {
         setisLoadingDates(false);
      }
   }

   // Funcio per pujar els turnos
    const pujarTurnos = async () => {
        if (!selectedFileTurnos) {
            console.error('Ningún arxiu seleccionat');
            return;
        }

        const formData = new FormData();
        formData.append('excel', selectedFileTurnos);

        try {
            setisLoadingTurnos(true);
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/excel/turnos', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });
            const data = await response.json();
            if (data.status === 'success') {
                seterrorTurnos('');
                storage.remove('events');
                setmissatgeTurnos(data.message);
            } else {
                setmissatgeTurnos('');
                seterrorTurnos('Error al importar l\'excel');
            }
        } catch (error) {
            console.error('Error:', error);
            seterrorTurnos('Error al importar l\'excel');
        } finally {
            setisLoadingTurnos(false);
        }
    };

    const canviparques = async () => {
        try {
            setisLoadingParques(true);
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/dades/canviarParque', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.status === 'success') {
                seterrorParques('');
                storage.remove('events');
                setmissatgeParques('Parques actualitzats correctament');
            } else {
                setmissatgeParques('');
                seterrorParques('Error al canviar els parques');
            }
        } catch (error) {
            console.error('Error:', error);
            seterrorParques('Error al canviar els parques');
        } finally {
            setisLoadingParques(false);
        }
    }

    const esborrarTurnos = async () => {
        try {
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/turnos/esborrar', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.status === 'success') {
                seterrorTurnos('');
                storage.remove('events');
                setmissatgeTurnos('Turnos esborrats correctament');
            } else {
                setmissatgeTurnos('');
                seterrorTurnos('Error al esborrar els turnos');
            }

        } catch (error) {
            console.error('Error:', error);
            seterrorTurnos('Error al esborrar els turnos');
        }
        finally {
            setisLoadingTurnos(false);
        }
    }
   
   const esborrarDates = async () => {
      try {
         const response = await fetch(
            "https://aeroguardias.es/aeroguardiasapi/public/api/esborrardates",
            {
               method: "DELETE",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         const data = await response.json();
         if (data.status === 'success') {
            seterrorDates('');
            storage.remove('events');
            setmissatgeDates('Dates esborrades correctament');
         }
         else {
            setmissatgeDates('');
            seterrorDates('Error al esborrar les dates');
         }
      } catch (error) {
         console.error('Error:', error);
         seterrorDates('Error al esborrar les dates');
      }
      finally {
         setisLoadingDates(false);
      }

   };

    return (
       <main>
          <div className="admin-container">
             <hr />
             <h2>Mantenimient dels Turnos</h2>
             <hr />
             <div className="excel-container">
                <div className="excel-container-body">
                   <div className="excel-file">
                      <input
                         type="file"
                         accept=".xlsx,.xls"
                         className="form-control"
                         onChange={handleFileChangeTurnos}
                      />
                   </div>
                   <div className="botons-excels">
                      <Button variant="info" onClick={pujarTurnos}>
                         Pujar arxiu
                      </Button>
                      <Button
                         variant="danger"
                         onClick={handleShowDeleteShiftsModal}
                      >
                         Esborrar Turnos
                      </Button>
                   </div>
                </div>
                <br />
                {isLoadingTurnos && (
                   <div className="spinner-excels">
                      Actualitzant Turnos...
                      <Spinner animation="border" />
                   </div>
                )}
                {errorTurnos !== "" && (
                   <Alert variant="danger">{errorTurnos}</Alert>
                )}
                {missatgeTurnos !== "" && (
                   <Alert variant="success">{missatgeTurnos}</Alert>
                )}
             </div>
             <hr />
             <h2>Importar Dies amb Lletra</h2>
             <hr />
             <div className="excel-container">
                <div className="excel-container-body">
                   <div className="excel-file">
                      <input
                         type="file"
                         accept=".xlsx,.xls"
                         className="form-control"
                         onChange={handleFileChangeDates}
                      />
                   </div>
                   <div className="botons-excels">
                      <Button variant="info" onClick={pujarDates}>
                         Pujar arxiu
                      </Button>
                      <Button
                         variant="danger"
                         onClick={handleShowDeleteDatesModal} 
                      >
                         Esborrar Dates
                      </Button>
                   </div>
                </div>
                <br />
                {isLoadingDates && (
                   <div className="spinner-excels">
                      Actualitzant Dates...
                      <Spinner animation="border" />
                   </div>
                )}
                {errorDates !== "" && (
                   <Alert variant="danger">{errorDates}</Alert>
                )}
                {missatgeDates !== "" && (
                   <Alert variant="success">{missatgeDates}</Alert>
                )}
             </div>
             <hr />
             <div className="excel-container">
                <div className="excel-container-body">
                   <div className="botons-excels">
                      <div className="botons-titol">
                         <h2>Canvis de parque</h2>
                      </div>
                      <Button
                         variant="warning"
                         onClick={handleShowChangeParkModal}
                      >
                         Forçar canvi de parque
                      </Button>
                   </div>
                </div>
                <hr />
                <br />
                {isLoadingParques && (
                   <div className="spinner-excels">
                      Actualitzant parques...
                      <Spinner animation="border" />
                   </div>
                )}
                {errorParques !== "" && (
                   <Alert variant="danger">{errorParques}</Alert>
                )}
                {missatgeParques !== "" && (
                   <Alert variant="success">{missatgeParques}</Alert>
                )}
             </div>

             {/* Modal de turnos */}
             <Modal
                show={showDeleteShiftsModal}
                onHide={handleCloseDeleteShiftsModal}
                centered
             >
                <Modal.Header closeButton>
                   <Modal.Title>Confirmació</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <div id="alertes-modals">
                      <div className="logout-info">
                         <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-exclamation-triangle"
                            viewBox="0 0 16 16"
                         >
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                         </svg>
                         <div>Segur que vols esborrar tots els turnos?</div>
                      </div>
                   </div>
                </Modal.Body>
                <Modal.Footer>
                   <Button
                      variant="secondary"
                      onClick={handleCloseDeleteShiftsModal}
                   >
                      Cancel·lar
                   </Button>
                   <Button
                      variant="danger"
                      onClick={() => {
                         esborrarTurnos();
                         handleCloseDeleteShiftsModal();
                      }}
                   >
                      Esborrar
                   </Button>
                </Modal.Footer>
             </Modal>

             {/* Modal de Dates */}
             <Modal
                show={showDeleteDatesModal}
                onHide={handleCloseDeleteDatesModal}
                centered
             >
                <Modal.Header closeButton>
                   <Modal.Title>Confirmació</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <div id="alertes-modals">
                      <div className="logout-info">
                         <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-exclamation-triangle"
                            viewBox="0 0 16 16"
                         >
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                         </svg>
                         <div>Segur que vols esborrar totes les dates?</div>
                      </div>
                   </div>
                </Modal.Body>
                <Modal.Footer>
                   <Button
                      variant="secondary"
                      onClick={handleCloseDeleteDatesModal}
                   >
                      Cancel·lar
                   </Button>
                   <Button
                      variant="danger"
                      onClick={() => {
                         esborrarDates();
                         handleCloseDeleteDatesModal();
                      }}
                   >
                      Esborrar
                   </Button>
                </Modal.Footer>
             </Modal>

             {/* Modal de Confirmación para Cambio de Parque */}
             <Modal
                show={showChangeParkModal}
                onHide={handleCloseChangeParkModal}
                centered
             >
                <Modal.Header closeButton>
                   <Modal.Title>Confirmació de Canvi de Parque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   Estàs segur que vols forçar el canvi de parque?
                </Modal.Body>
                <Modal.Footer>
                   <Button
                      variant="secondary"
                      onClick={handleCloseChangeParkModal}
                   >
                      Cancel·lar
                   </Button>
                   <Button variant="danger" onClick={handleConfirmChangePark}>
                      Confirmar Canvi
                   </Button>
                </Modal.Footer>
             </Modal>

             <div>
                <VisibilitatTurnos api_token={token} />
                <hr />
             </div>
          </div>
       </main>
    );
};

export default Lletresirolsgestor;
