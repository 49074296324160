import React, { useState, useEffect} from 'react';
import { Row, Col, Button, Alert, Form } from 'react-bootstrap';
import { storage } from '../../utils/storage';

export default function NotesBomberoAfegeix(props) {
    const token = props.token; 
    const id = props.usuari_id;
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [mostrarMissatgeconf, setMostrarmissatgeconf] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [note, setNote] = useState('');
    const [notadata, setNotaData] = useState('');
    const [showForm, setShowForm] = useState(false); // Estado para controlar la visibilidad del formulario

    const handleSubmit = (e) => {
        e.preventDefault();
        afegirNota();
        setNote('');
        setShowForm(false); // Al enviar el formulario, ocultamos el formulario nuevamente
    };

    useEffect(() => {
        if (error !== '') {
            setShowError(true); // Muestra el alerta de error
            const timer = setTimeout(() => {
                setShowError(false); // Oculta el alerta después de 4 segundos
                setError(''); // Limpia el mensaje de error
            }, 4000);
            return () => clearTimeout(timer); // Limpia el timer si el componente se desmonta o si error cambia antes de que pase el tiempo
        }

        if (confirmationMessage !== '') {
            setMostrarmissatgeconf(true); // Muestra el mensaje de confirmación
            const timer = setTimeout(() => {
                setMostrarmissatgeconf(false); // Oculta el mensaje de confirmación después de 4 segundos
                setConfirmationMessage(''); // Limpia el mensaje de confirmación después de 4 segundos
            }, 4000);
            return () => clearTimeout(timer); // Limpia el timer si el componente se desmonta o si confirmationMessage cambia antes de que pase el tiempo
        }

    }, [error, confirmationMessage]); // Dependencia al estado 'error'


    const afegirNota = async () => {
        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/notes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ usuari_id: id, nota: note, data: notadata})
            });

            if (!response.ok) {
                console.log(response);
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Error al afegir la nota');
            }
    
            const data = await response.json();
            setConfirmationMessage('Nota afegida correctament! Actualitza la pàgina per veurer-la!');
            storage.remove('notesCalendari');
            window.location.reload();
    
        } catch (error) {
            console.error('Error al afegir la nota:', error);
            setError(error.message || 'Error al afegir la nota');
        }
    }
    

    return (
        <>
            <Row md={9}>
                <Col>
                    <h1>Notes 📝</h1>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    {/* Cambia el estado showForm al hacer clic en el botón */}
                    <Button
                        className='botoNormal-notes'
                        variant={showForm ? 'danger' : 'warning'}
                        type="button"
                        onClick={() => setShowForm(!showForm)}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text" viewBox="0 0 16 16">
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/>
                    </svg>  {showForm ? 'Cancelar' : 'Crear nova nota'}
                    </Button>
                </Col>
            </Row>
            {/* Mostrar el formulario solo si showForm es verdadero */}
            {showForm && (
                <div>
                    <hr />
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="noteForm" className='afegirnotes'>
                            <Form.Label>Afegeix una nova nota:</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                value={note} 
                                onChange={(e) => setNote(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group controlId="notadataForm" className='afegirnotes'>
                            <Form.Label>Data de la nota:</Form.Label>
                            <Form.Control 
                                type="date" 
                                value={notadata} 
                                onChange={(e) => setNotaData(e.target.value)} 
                            />
                        </Form.Group>
                        <div className='botons-edicio'>
                            <Button variant="success" type="submit" >
                                Afegir
                            </Button>
                        </div>
                    </Form>
                    <br />
                </div>
            )}
            {mostrarMissatgeconf && (
                <div className="alert alert-success" role="alert">
                    <p>{confirmationMessage}</p>
                </div>
            )}
            {showError && <Alert variant="danger">{error}</Alert>}
        </>
    );
}
