import React from "react";
import "../style/Style.css";

const Ajuda = () => {
   return (
      <main>
            <div className="help-container">
               <div className="ajuda-titol">
                  <h2>Ajuda de l'Aplicació</h2>
               </div>
               <div className="ajuda-subtitol">
                  <p>
                     Aquesta aplicació està dissenyada per facilitar la gestió
                     d'horaris i tasques per als bombers d'aeroports. Aquí
                     trobaràs les principals funcionalitats:
                  </p>
               </div>
               <div className="ajuda-contingut">
                  <h3>1. Gestió d'Horaris</h3>
                  <p>
                     La gestió d'horaris és una de les funcionalitats més
                     importants de l'aplicació. Aquí pots veure els torns
                     assignats, les tasques a realitzar i els companys amb els
                     quals compartiràs torn.
                  </p>
                  <h3>2. Gestió de Vacançes</h3>
                  <p>
                     Per gestionar les teves vacances, fes clic a l'opció de
                     Vacances al menú principal. Allà podràs veure les vacances
                     sol·licitades i pendents de confirmació.
                  </p>
                  <h3>3. Canvis de Torn</h3>
                  <p>
                     Si necessites canviar un torn, fes clic a l'opció de Canvis
                     de Torn al menú principal. Allà podràs veure els canvis
                     sol·licitats i pendents de confirmació.
                  </p>
                  <h3>4. Comunicació Interna</h3>
                  <p>
                     Per enviar un missatge a l'administrador o a un company,
                     fes clic a l'opció de Missatges al menú principal. Allà
                     podràs escriure un missatge i enviar-lo a la persona
                     corresponent.
                  </p>
                  <h3>5. Organitzador de Despeses</h3>
                  <p>
                     Per gestionar les despeses de menjars de grup, fes clic a
                     l'opció de Despeses al menú principal. Allà podràs veure les
                     despeses pendents de pagament i registrar-ne de noves.
                  </p>
               </div>
               <div className="ajuda-peu">
                  <p>
                     Per a més ajuda o informació, contacta amb l'administrador
                     o revisa la documentació proporcionada.
                  </p>
               </div>
            </div>
      </main>
   );
};

export default Ajuda;
