import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function NotesAfegeix(props) {
    const [nota, setNota] = useState('');
    const [usuariIdSeleccionat, setUsuariIdSeleccionat] = useState('');
    const [usuaris, setUsuaris] = useState([]);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        if (error) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                setError('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    useEffect(() => {
        // Aquí pots fer una crida a la teva API per obtenir la llista d'usuaris
        fetch('https://aeroguardias.es/aeroguardiasapi/public/api/usuaris', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            // Guarda la llista d'usuaris en l'estat del component
            setUsuaris(data.data);
            console.log(data);              
        })
        .catch(error => {
            console.error('Error:', error);
            // Maneja l'error aquí
        });
    }, []);

    const guardaNota = () => {
        // Verifica si la nota està buida
        if (nota.trim() === '') {
            setError('La nota no pot estar buida.');
            return;
        }

        // Verifica si s'ha seleccionat un usuari
        if (usuariIdSeleccionat === '') {
            setError('Si us plau, selecciona un usuari.');
            return;
        }

        // Envia la sol·licitud al servidor
        fetch('https://aeroguardias.es/aeroguardiasapi/public/api/notes', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                usuari_id: usuariIdSeleccionat,
                nota: nota
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en guardar la nota.');
            } else {
                setError('');
                navigate('/notes');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Error en guardar la nota.');
        });
    };

    return (
        <main>
            <hr />
            <h1>Afegir Nota</h1>
            <hr />
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Nota:</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Escriu la nota aquí"
                        value={nota}
                        onChange={(e) => setNota(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Usuari:</Form.Label>
                    <Form.Select
                        value={usuariIdSeleccionat}
                        onChange={(e) => setUsuariIdSeleccionat(e.target.value)}
                    >
                        <option value="">Selecciona un usuari</option>
                        {usuaris && usuaris.map((usuari) => (
                            <option key={usuari.id} value={usuari.id}>{usuari.nom}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <div className="botons-edicio">
                    <Button variant="primary" type="button" onClick={guardaNota}>
                        Guardar
                    </Button>
                    <Button variant="warning" type="button" onClick={() => navigate('/notes')}>
                        Cancel·lar
                    </Button>
                </div>
            </Form>
            <br />
            {error !== '' && <Alert variant="danger">{error}</Alert>}
        </main>
    );
}
