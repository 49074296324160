import React, { useState, useEffect} from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function UsuarisAfegeix(props) {
    const [nomUsuari, setNomUsuari] = useState('');
    const [llinatgesUsuari, setLlinatgesUsuari] = useState('');
    const [mailUsuari, setMailUsuari] = useState('');
    const [contrasenyaUsuari, setContrasenyaUsuari] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        if (error) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                setError('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const guardaUsuari = () => {
        // Verifica si hay campos vacíos
        if (nomUsuari.trim() === '' || mailUsuari.trim() === '' || contrasenyaUsuari.trim() === '') {
            setError('Tots els camps són obligatoris.');
            return;
        }

        // Envía la solicitud al servidor
        fetch('https://aeroguardias.es/aeroguardiasapi/public/api/usuaris', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                nom: nomUsuari,
                correu: mailUsuari,
                contrasenya: contrasenyaUsuari
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                // Si hay un error en la respuesta del servidor, verifica si hay un mensaje de error específico para la contraseña
                if (data.data.contrasenya && data.data.contrasenya.length > 0) {
                    setError(data.data.contrasenya[0]); // Establece el mensaje de error de la contraseña
                } else {
                    setError('Error en guardar l\'usuari.'); // Si no hay un mensaje específico, establece un mensaje genérico
                }
            } else {
                // Si la solicitud se completó con éxito, limpia el estado de error y redirige a la página de usuarios
                setError('');
                navigate('/usuaris');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('Error en guardar l\'usuari.');
        });
    };

    return (
        <main>
            <hr />
            <h1>Afegir Usuari</h1>
            <hr />
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Nom i llinatges:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nom de l'usuari"
                        value={nomUsuari}
                        onChange={(e) => setNomUsuari(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Correu:</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Correu de l'usuari"
                        value={mailUsuari}
                        onChange={(e) => setMailUsuari(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Contrasenya:</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Contrasenya de l'usuari"
                        value={contrasenyaUsuari}
                        onChange={(e) => setContrasenyaUsuari(e.target.value)}
                    />
                </Form.Group>
                <div className="botons-edicio">
                    <Button variant="primary" type="button" onClick={guardaUsuari}>
                        Guardar
                    </Button>
                    <Button variant="warning" type="button" onClick={() => navigate('/usuaris')}>
                        Cancel·lar
                    </Button>
                </div>
            </Form>
            <br />
            {error !== '' && <Alert variant="danger">{error}</Alert>}
        </main>
    );
}
