import React, { useState, useEffect } from "react";
import { Table, Alert, Spinner } from "react-bootstrap";

export default function UsuariDadesProfessionals(props) {
   const [nVacacions, setNVacacions] = useState(null);
   const [nAP, setNAP] = useState(null);
   const [grup, setGrup] = useState(null);
   const [parque, setParque] = useState(null);
   const [rol, setRol] = useState(null);
   const [error, setError] = useState("");
   const id = props.usuari_id;
   const token = props.api_token;
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      dadesprofessionals();
   }, [id, token]);

   const renderValue = (value) => {
      return (
         loading ? (
            <td>
               <Spinner animation="border" size="sm" />
            </td>
         ) : value !== null ? (
            <td>
               {value}
            </td>
         ) : (
            <td></td>
         )
      );
   };

   const contarAP = async () => {
      setLoading(true);
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/countAP/${id}`,
            {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         const data = await response.json();
         setNAP(data.data);
         setLoading(false);
      } catch (err) {
         setLoading(false);
         setError("Error al descarregar el nombre de AP de l'usuari");
         console.error(
            "Error en descarregar el nombre de AP de l'usuari:",
            error + err
         );
      }
   };

   const countVacacions = async () => {
      setLoading(true);
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/countVacacions/${id}`,
            {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         const data = await response.json();
         setNVacacions(data.data);
         setLoading(false);

      } catch (error) {
         setLoading(false);
         setError("Error al descarregar el nombre de vacançes de l'usuari");
         console.error(
            "Error en descarregar el nombre de vacançes de l'usuari:",
            error
         );
      }
   };

   const dadesprofessionals = async () => {
      setLoading(true);
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/dades/usuari/${id}`,
            {
               method: "GET",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         const data = await response.json();
         contarAP();
         countVacacions();
         setGrup(data.data.grup);
         setParque(data.data.parque);
         setRol(data.data.rol);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setError("Error al descarregar les dades de l'usuari");
         console.error("Error en descarregar les dades de l'usuari:", error);
      }
   };

   return (
      <div>
         <div className="dadesusuari">
            <table className="taula-dadesusuari">
               <thead>
                  <tr>
                     <th>Descripció</th>
                     <th>Detalls</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>Nº de Dies de Vacacions Emprats</td>
                     {renderValue(nVacacions)}
                  </tr>
                  <tr>
                     <td>Nº d'Assumptes Personals Emprats</td>
                     {renderValue(nAP)}
                  </tr>
                  <tr>
                     <td>Grup</td>
                     {renderValue(grup)}
                  </tr>
                  <tr>
                     <td>Parque al qual pertanys</td>
                     {renderValue(parque)}
                  </tr>
                  <tr>
                     <td>El teu Rol Actual</td>
                     {renderValue(rol)}
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
}
