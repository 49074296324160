import React, { useState, useEffect } from "react";
import UsuariDades from "./UsuariDades";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import UsuariDadesProfessionals from "./UsuariDadesProfessionals";
import UsuariDadesProfessionalsForm from "./UsuariDadesProfessionalsForm";
import "../style/Style.css";
import { NavLink, Link } from "react-router-dom";

export default function Usuari(props) {
   const usuari_nom = props.usuari_nom || "";
   const usuari_id = props.usuari_id;
   const token = props.api_token;
   const turnosv = props.turnos_visibles;
   const [turnosVisibles, setTurnosVisibles] = useState(turnosv);
   const [showForm, setShowForm] = useState(false);
   const [showFormProfessionals, setShowFormProfessionals] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [modalAction, setModalAction] = useState(null);
   const [alertVisible, setAlertVisible] = useState(false);
   const [alertMessage, setAlertMessage] = useState("");
   const [alertVariant, setAlertVariant] = useState("success");
   const [notificacionsusuari, setNotificacionsUsuari] = useState(false);
   const [correuNotificacions, setCorreuNotificacions] = useState("");
   const [showNotificacionsModal, setShowNotificacionsModal] = useState(false);
   const [notificacionsAction, setNotificacionsAction] = useState(null);

   const activarNotificacions = async (correu) => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/activanotificacions/${usuari_id}`,
            {
               method: "POST",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
               },
               body: JSON.stringify({ correu_notificacions: correu }),
            }
         );
         const data = await response.json();
         if (response.ok) {
            setNotificacionsUsuari(true);
            setCorreuNotificacions(correu);
         setAlertMessage(
            "Notificacions activades correctament. Rebràs un correu de confirmació."
         );

            setAlertVariant("success");
         } else {
            console.error("Error al activar las notificaciones");
            setAlertMessage(
               "Error al activar las notificacions"
            );
            setAlertVariant("danger");
         }
      } catch (error) {
         console.error("Error al activar las notificaciones:", error);
         setAlertMessage(
            "Error al activar las notificacions"
         );
         setAlertVariant("danger");
      } finally {
         setAlertVisible(true);
      }
   };

   const desactivarNotificacions = async () => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/desactivanotificacions/${usuari_id}`,
            {
               method: "PUT",
               headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         const data = await response.json();
         if (response.ok) {
            setNotificacionsUsuari(false);
            setCorreuNotificacions("");
            setAlertMessage(
               "Notificacions desactivades correctament"
            );
            setAlertVariant("success");
         } else {
            console.error("Error al desactivar las notificacions");
                              setAlertMessage(
                                 "Error al desactivar las notificacions"
                              );
                              setAlertVariant("danger");
         }
      } catch (error) {
         console.error("Error al desactivar las notificacions:", error);
                  setAlertMessage("Error al desactivar las notificacions");
                  setAlertVariant("danger");
      } finally {
         setAlertVisible(true);
      }
   };


   useEffect(() => {
      const fetchTurnosVisibles = async () => {
         try {
            const response = await fetch(
               `https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/token/${token}`,
               {
                  method: "GET",
                  headers: {
                     Accept: "application/json",
                     "Content-Type": "application/json",
                     Authorization: `Bearer ${token}`,
                  },
               }
            );
            const data = await response.json();
            if (response.ok) {
               setTurnosVisibles(data.data.turnos_visibles);
               setNotificacionsUsuari(data.data.notificacions); // Añadir esta línea
            } else {
               console.error("Error al obtenir l'estat dels turnos");
            }
         } catch (error) {
            console.error("Error al obtenir l'estat dels turnos:", error);
         }
      };

      fetchTurnosVisibles();
   }, [token]);

   const lastSpaceIndex = usuari_nom.lastIndexOf(" ");
   const nombre =
      lastSpaceIndex !== -1
         ? usuari_nom.substring(lastSpaceIndex + 1).toUpperCase()
         : "";

   const mostrarTurnos = async () => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/turnosvisibles/${usuari_id}`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         if (response.ok) {
            setTurnosVisibles(true);
            setAlertMessage("Visibilitat dels turnos canviada correctament");
            setAlertVariant("success");
            canviarvisibilitatturnos();
         } else {
            console.error("Error al canviar la visibilitat dels turnos");
            setAlertMessage("Error al canviar la visibilitat dels turnos");
            setAlertVariant("danger");
         }
      } catch (error) {
         console.error("Error al canviar la visibilitat dels turnos:", error);
         setAlertMessage("Error al canviar la visibilitat dels turnos");
         setAlertVariant("danger");
      } finally {
         setAlertVisible(true);
      }
   };

   const ocultarTurnos = async () => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/turnosnovisibles/${usuari_id}`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         if (response.ok) {
            setTurnosVisibles(false);
            setAlertMessage("Visibilitat dels turnos canviada correctament");
            setAlertVariant("success");
            canviarvisibilitatturnos();
         } else {
            console.error("Error al canviar la visibilitat dels turnos");
            setAlertMessage("Error al canviar la visibilitat dels turnos");
            setAlertVariant("danger");
         }
      } catch (error) {
         console.error("Error al canviar la visibilitat dels turnos:", error);
         setAlertMessage("Error al canviar la visibilitat dels turnos");
         setAlertVariant("danger");
      } finally {
         setAlertVisible(true);
      }
   };

   const canviarvisibilitatturnos = async () => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/canviartv/${usuari_id}`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         if (response.ok) {
            setTurnosVisibles(!turnosVisibles);
         } else {
            console.error("Error al canviar la visibilitat dels turnos");
         }
      } catch (error) {
         console.error("Error al canviar la visibilitat dels turnos:", error);
      }
   };

   const handleModalShow = (action) => {
      setModalAction(action);
      setShowModal(true);
   };

   const handleModalConfirm = () => {
      if (modalAction === "mostrar") {
         mostrarTurnos();
      } else if (modalAction === "ocultar") {
         ocultarTurnos();
      }
      setShowModal(false);
   };

   return (
      <main>
         <div>
            <div className="user-section">
               <div className="dades-usuari">
                  <div className="dades-usuari-titol">
                     <div className="dades-titol">
                        <h1>HOLA {nombre}! 🚒🔥</h1>
                     </div>
                     <div className="boto-anar-calendari">
                        {turnosVisibles == 0 ? (
                           <Button
                              id="boto-mostrar-turnos"
                              onClick={() => handleModalShow("mostrar")}
                              variant="info"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="currentColor"
                                 className="bi bi-eye-slash-fill"
                                 viewBox="0 0 16 16"
                              >
                                 <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                 <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                              </svg>
                              Mostrar turnos
                           </Button>
                        ) : (
                           <Button
                              id="boto-ocultar-turnos"
                              onClick={() => handleModalShow("ocultar")}
                              variant="warning"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="currentColor"
                                 className="bi bi-eye-fill"
                                 viewBox="0 0 16 16"
                              >
                                 <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                 <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                              </svg>
                              Ocultar turnos
                           </Button>
                        )}
                        <div className="linkinot">
                           <Link
                              as={NavLink}
                              to="/calendari"
                              className="btn btn-success"
                              id="link-calendari"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="currentColor"
                                 className="bi bi-calendar-date"
                                 viewBox="0 0 16 16"
                              >
                                 <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23" />
                                 <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                              </svg>
                              Veure el meu calendari
                           </Link>
                           {/* Activar o desactivar notificacions per correu */}
                           {!notificacionsusuari ? (
                              <Button
                                 id="notificacions-desactivades-boto"
                                 variant="info"
                                 onClick={() => {
                                    setNotificacionsAction("activar");
                                    setShowNotificacionsModal(true);
                                 }}
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-bell-slash-fill"
                                    viewBox="0 0 16 16"
                                 >
                                    <path d="M5.164 14H15c-1.5-1-2-5.902-2-7q0-.396-.06-.776zm6.288-10.617A5 5 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5 5 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z" />
                                 </svg>
                              </Button>
                           ) : (
                              <Button
                                 id="notificacions-desactivades-boto"
                                 variant="info"
                                 onClick={() => {
                                    setNotificacionsAction("desactivar");
                                    setShowNotificacionsModal(true);
                                 }}
                              >
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-bell-fill"
                                    viewBox="0 0 16 16"
                                 >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                                 </svg>
                              </Button>
                           )}
                        </div>
                     </div>
                  </div>
                  <div className="boto-mostrar-dades-personals">
                     {showForm ? (
                        <UsuariDades
                           usuari_id={usuari_id}
                           api_token={token}
                           setShowForm={setShowForm}
                        />
                     ) : (
                        <div className="botons-dades">
                           <Button
                              id="mostrardades"
                              onClick={() => setShowForm(true)}
                              variant="secondary"
                           >
                              <text>
                                 Veure o modificar les meves dades personals
                              </text>
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="currentColor"
                                 className="bi bi-gear-wide-connected"
                                 viewBox="0 0 16 16"
                              >
                                 <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z" />
                              </svg>
                           </Button>
                        </div>
                     )}

                     {showFormProfessionals ? (
                        <UsuariDadesProfessionalsForm
                           usuari_id={usuari_id}
                           api_token={token}
                           setShowFormProfessionals={setShowFormProfessionals}
                        />
                     ) : (
                        <div className="botons-dades">
                           <Button
                              id="editadadesprofessionals"
                              onClick={() => setShowFormProfessionals(true)}
                              variant="secondary"
                           >
                              <text>
                                 Modificar les meves dades professionals
                              </text>
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="currentColor"
                                 className="bi bi-gear-wide-connected"
                                 viewBox="0 0 16 16"
                              >
                                 <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z" />
                              </svg>
                           </Button>
                        </div>
                     )}
                  </div>
               </div>
               <br />
               <div className="dadesprofessionals-titol">
                  <h3 className="dades-usuari">Dades professionals</h3>
               </div>
               <UsuariDadesProfessionals
                  usuari_id={usuari_id}
                  api_token={token}
               />
            </div>
         </div>

         <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
               <Modal.Title>Confirmar Acció</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Alert variant="warning">
                  <div className="logout-info">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-exclamation-triangle"
                        viewBox="0 0 16 16"
                     >
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                     </svg>
                     <div>
                        {modalAction === "mostrar"
                           ? "Segur que vols mostrar els turnos?"
                           : "Segur que vols ocultar els turnos?"}
                     </div>
                  </div>
               </Alert>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Cancelar
               </Button>
               <Button variant="primary" onClick={handleModalConfirm}>
                  Confirmar
               </Button>
            </Modal.Footer>
         </Modal>

         <Modal
            show={showNotificacionsModal}
            onHide={() => setShowNotificacionsModal(false)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title>Confirmar Acció</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Alert variant="info">
                  <div className="logout-info">
                     <div>
                        {notificacionsAction === "activar"
                           ? "Segur que vols activar les notificacions?"
                           : "Segur que vols desactivar les notificacions?"}
                     </div>
                  </div>
               </Alert>
               {notificacionsAction === "activar" && (
                  <Form.Group controlId="formCorreuNotificacions">
                     <Form.Label>
                        Introdueix el teu correu electrònic:
                     </Form.Label>
                     <Form.Control
                        type="email"
                        placeholder="correu@exemple.com"
                        value={correuNotificacions}
                        onChange={(e) => setCorreuNotificacions(e.target.value)}
                        required
                     />
                  </Form.Group>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => setShowNotificacionsModal(false)}
               >
                  Cancelar
               </Button>
               <Button
                  variant="primary"
                  onClick={(e) => {
                     e.preventDefault();
                     if (
                        notificacionsAction === "activar" &&
                        correuNotificacions
                     ) {
                        activarNotificacions(correuNotificacions);
                        setShowNotificacionsModal(false);
                     } else if (notificacionsAction === "desactivar") {
                        desactivarNotificacions();
                        setShowNotificacionsModal(false);
                     } else if (
                        notificacionsAction === "activar" &&
                        !correuNotificacions
                     ) {
                        alert("Per favor, introdueix un correu electrònic.");
                     }
                  }}
                  disabled={
                     notificacionsAction === "activar" && !correuNotificacions
                  } // Deshabilita el botón si no hay correo
               >
                  Confirmar
               </Button>
            </Modal.Footer>
         </Modal>

         {alertVisible && (
            <div className="fondo-alert">
               <div className="alert-wrapper">
                  <Alert
                     variant={alertVariant}
                     onClose={() => setAlertVisible(false)}
                     dismissible
                     style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10000,
                     }}
                  >
                     {alertMessage}
                  </Alert>
               </div>
            </div>
         )}
      </main>
   );
}
