import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export default function GastosCRUD(props) {
    const [concepte, setConcepte] = useState('');
    const [importGast, setImportGast] = useState('');
    const [usuariId, setUsuariId] = useState('');
    const [usuaris, setUsuaris] = useState([]);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [descarregant, setDescarregant] = useState(false);
    const [edita, setEdita] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const token = props.api_token;

    useEffect(() => {
        if (id !== "-1") {
            descarregaGasto();
        } else {
            setEdita(false);
        }
        descarregaUsuaris();
    }, [id]);

    useEffect(() => {
        if (error) {
            setShowError(true);
            const timer = setTimeout(() => {
                setShowError(false);
                setError('');
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const descarregaGasto = async () => {
        setDescarregant(true);
        setEdita(true);
        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/gastos/${id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            const { concepte, import: importGast, usuari_id } = responseData.data;
            setConcepte(concepte);
            setImportGast(importGast);
            setUsuariId(usuari_id);
        } catch (error) {
            console.log(error);
        }
        setDescarregant(false);
    }

    const descarregaUsuaris = async () => {
        try {
            const response = await fetch('https://aeroguardias.es/aeroguardiasapi/public/api/usuaris', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUsuaris(data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const guardaGasto = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/gastos/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                concepte: concepte,
                import: importGast,
                usuari_id: usuariId
            })
        }).then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en guardar el gasto.');
            } else {
                setError('');
                navigate('/gastos');
            }
        })
    }

    const esborraGasto = () => {
        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/gastos/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then(response => response.json())
        .then(data => {
            if (data.status === 'error') {
                setError('Error en esborrar el gasto.');
            } else {
                setError('');
                navigate('/gastos');
            }
        })
    }

    if (descarregant) {
        return (<main>
            <div className='spinner'>
                <Spinner/>
            </div>
        </main>);
    }

    return (
        <main>
            <hr />
            <h1>{edita ? 'Editar' : 'Afegir'} Gasto</h1>
            <hr />
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Concepte:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Introdueix el concepte"
                        value={concepte}
                        onChange={(e) => setConcepte(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Import:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Introdueix l'import"
                        value={importGast}
                        onChange={(e) => setImportGast(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Usuari:</Form.Label>
                    <Form.Select
                        value={usuariId}
                        onChange={(e) => setUsuariId(e.target.value)}
                    >
                        {usuaris && usuaris.map((usuari) => (
                            <option key={usuari.id} value={usuari.id}>
                                {usuari.nom}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <div className="botons-edicio">
                    <Button variant="primary" type="button" onClick={guardaGasto}>Guardar</Button>
                    <Button variant="warning" type="button" onClick={() => navigate("/gastos")}>Cancel·lar</Button>
                    {edita && (
                        <Button variant="danger" type="button" onClick={esborraGasto}>Esborrar</Button>
                    )}
                </div>
            </Form>
            {error !== "" && <Alert variant="danger">{error}</Alert>}
        </main>
    );
}
