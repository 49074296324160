import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Form, Alert, Modal, Spinner } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default function VisibilitatTurnos({ api_token }) {
   const [usuaris, setUsuaris] = useState([]);
   const token = api_token;
   const [filteredUsuaris, setFilteredUsuaris] = useState([]);
   const [loading, setLoading] = useState(true);
   const [searchTerm, setSearchTerm] = useState("");
   const [visibilityFilter, setVisibilityFilter] = useState("all");
   const [showModal, setShowModal] = useState(false);
   const [modalAction, setModalAction] = useState(null);
   const [alertVisible, setAlertVisible] = useState(false);
   const [alertMessage, setAlertMessage] = useState("");
   const [alertVariant, setAlertVariant] = useState("success");
   const [selectedUserId, setSelectedUserId] = useState(null);
   const selectRef = useRef(null);

   useEffect(() => {
      fetchUsuaris();
   }, []);

   const fetchUsuaris = async () => {
      try {
         const response = await fetch("https://aeroguardias.es/aeroguardiasapi/public/api/usuaris", {
            method: "GET",
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Bearer ${api_token}`,
            },
         });
         const data = await response.json();
         setUsuaris(data.data);
         setFilteredUsuaris(data.data);
      } catch (error) {
         console.error("Error al decarregar els usuaris:", error);
      }
      setLoading(false);
   };

   const handleSearch = (event) => {
      const searchTerm = event.target.value.toLowerCase();
      setSearchTerm(searchTerm);
      filterUsuaris(searchTerm, visibilityFilter);
   };

   const handleVisibilityFilterChange = (event) => {
      const filter = event.target.value;
      setVisibilityFilter(filter);
      filterUsuaris(searchTerm, filter);
   };

   const filterUsuaris = (searchTerm, visibilityFilter) => {
      let filtered = usuaris.filter((usuario) =>
         usuario.nom.toLowerCase().includes(searchTerm)
      );

      if (visibilityFilter === "Usuaris amb turnos visibles") {
         filtered = filtered.filter((usuario) => usuario.turnos_visibles === 1);
      } else if (visibilityFilter === "Usuaris amb turnos ocults") {
         filtered = filtered.filter((usuario) => usuario.turnos_visibles === 0);
      }

      setFilteredUsuaris(filtered);
   };

   const handleRowClick = (params) => {
      const user = params.data;
      setSelectedUserId(user.id);
      setModalAction(user.turnos_visibles ? "ocultar" : "mostrar");
      setShowModal(true);
   };

   const handleModalConfirm = async () => {
      if (modalAction === "mostrar") {
         await changeTurnosVisibilidad(selectedUserId, true);
      } else if (modalAction === "ocultar") {
         await changeTurnosVisibilidad(selectedUserId, false);
      }
      setShowModal(false);
      fetchUsuaris();
   };

   const canviarvisibilitatturnos = async (userId) => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/canviartv/${userId}`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         if (response.ok) {
            console.log("Visibilitat dels turnos canviada correctament");
         } else {
            console.error("Error al canviar la visibilitat dels turnos");
         }
      } catch (error) {
         console.error("Error al canviar la visibilitat dels turnos:", error);
      }
   };

   const changeTurnosVisibilidad = async (userId, visible) => {
      try {
         const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/${
               visible ? "turnosvisibles" : "turnosnovisibles"
            }/${userId}`,
            {
               method: "GET",
               headers: {
                  Authorization: `Bearer ${api_token}`,
               },
            }
         );
         if (response.ok) {
            await canviarvisibilitatturnos(userId); // Llamada a la función después de cambiar la visibilidad
            setFilteredUsuaris((prevData) =>
               prevData.map((user) =>
                  user.id === userId
                     ? { ...user, turnos_visibles: visible }
                     : user
               )
            );
            setAlertMessage("Visibilitat dels turnos canviada correctament");
            setAlertVariant("success");
         } else {
            throw new Error("Error al canviar la visibilitat dels turnos");
         }
      } catch (error) {
         console.error(error);
         setAlertMessage("Error al canviar la visibilitat dels turnos");
         setAlertVariant("danger");
      } finally {
         setAlertVisible(true);
      }
   };

   const columnDefs = [{ field: "nom", headerName: "Nom", flex: 1 }];

   const getRowStyle = (params) => {
      return {
         backgroundColor: params.data.turnos_visibles
            ? "lightgreen"
            : "lightcoral",
      };
   };

   useEffect(() => {
      if (selectRef.current) {
         switch (visibilityFilter) {
            case "all":
               selectRef.current.style.backgroundColor = "";
               break;
            case "Usuaris amb turnos visibles":
               selectRef.current.style.backgroundColor = "lightgreen";
               break;
            case "Usuaris amb turnos ocults":
               selectRef.current.style.backgroundColor = "lightcoral";
               break;
            default:
               selectRef.current.style.backgroundColor = "";
         }
      }
   }, [visibilityFilter]);

   if (loading) {
      return (
         <main>
            <div className="spinner">
               <Spinner />
            </div>
         </main>
      );
   } else {
      return (
         <main>
            <hr />
            <Row md={9}>
               <div className="titularTaules">
                  <div className="titolTaula">
                     <h2>Visibilitat dels Turnos</h2>
                  </div>
                  <div className="capcalera-cruds-visibilitat">
                     <Form.Control
                        type="text"
                        placeholder="Cercar Usuaris"
                        value={searchTerm}
                        onChange={handleSearch}
                     />
                     <Form.Control
                        as="select"
                        id="visibility-select"
                        value={visibilityFilter}
                        ref={selectRef}
                        onChange={handleVisibilityFilterChange}
                        style={{ marginLeft: "10px" }}
                     >
                        <option className="option-all" value="all">
                           Mostrar Tots
                        </option>
                        <option value="Usuaris amb turnos visibles">
                           Usuaris amb turnos visibles
                        </option>
                        <option value="Usuaris amb turnos ocults">
                           Usuaris amb turnos ocults
                        </option>
                     </Form.Control>
                  </div>
               </div>
            </Row>
            <hr />
            <div
               className="ag-theme-quartz"
               style={{
                  height: 550,
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "RobotoMedium",
               }}
            >
               <AgGridReact
                  rowData={filteredUsuaris}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationPageSize={9}
                  getRowStyle={getRowStyle}
                  onRowClicked={handleRowClick}
                  domLayout="autoHeight"
               />
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
               <Modal.Header closeButton>
                  <Modal.Title>Confirmar Acció</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Alert variant="warning">
                     <div className="logout-info">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="16"
                           height="16"
                           fill="currentColor"
                           class="bi bi-exclamation-triangle"
                           viewBox="0 0 16 16"
                        >
                           <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                           <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                        </svg>
                        <div>
                           {modalAction === "mostrar"
                              ? "Segur que vols mostrar els turnos d'aquest bomber?"
                              : "Segur que vols ocultar els turnos d'aquest bomber?"}{" "}
                        </div>
                     </div>
                  </Alert>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => setShowModal(false)}
                  >
                     Cancelar
                  </Button>
                  <Button variant="primary" onClick={handleModalConfirm}>
                     Confirmar
                  </Button>
               </Modal.Footer>
            </Modal>

            {alertVisible && (
               <div className="fondo-alert">
                  <div className="alert-wrapper">
                     <Alert
                        variant={alertVariant}
                        onClose={() => setAlertVisible(false)}
                        dismissible
                        style={{
                           position: "fixed",
                           top: "50%",
                           left: "50%",
                           transform: "translate(-50%, -50%)",
                           zIndex: 10000,
                        }}
                     >
                        {alertMessage}
                     </Alert>
                  </div>
               </div>
            )}
         </main>
      );
   }
}
