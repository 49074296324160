import React, { useState } from 'react';
import { Alert, Spinner, Form, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logoaeroguardias.webp';
import { NavLink, Link } from 'react-router-dom';

export default function Login(props) {
    const [mail, setMail] = useState('');
    const [contrasenya, setContrasenya] = useState('');
    const [error, setError] = useState(false);
    const [missatgeError, setMissatgeError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [mostrarContrasenya, setMostrarContrasenya] = useState(false);
    const [mostraCheckboxPolitica, setMostraCheckboxPolitica] = useState(false);
    const [acceptaPolitica, setAcceptaPolitica] = useState(false);
    const [idus, setIdus] = useState('');

    const handleToggleContrasenya = () => {
        setMostrarContrasenya(!mostrarContrasenya);
    };

    const ferLogin = () => {
        let data = { correu: mail, contrasenya: contrasenya };
        setLoading(true);

        fetch('https://aeroguardias.es/aeroguardiasapi/public/api/login', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la petició');
            }
            return response.json();
        })
        .then(respostaJson => {
            setLoading(false);

            if (respostaJson.status === "error") {
                setError(true);
                setMissatgeError(respostaJson.info || 'Usuari donat de baixa');
                return;
            }

            if (respostaJson.status === "success" && respostaJson.data && respostaJson.data.api_token) {
                let api_token = respostaJson.data.api_token;
                let usuari_id = respostaJson.data.id;
                let usuari_nom = respostaJson.data.nom;

                props.guardaapi_token(api_token);
                props.guardausuari_id(usuari_id);
                props.guardausuari_nom(usuari_nom);
                navigate('/usuari');
                setError(false);
                // window.location.href ='/usuari';
            } else if (respostaJson.status === "needs_activation") {
                setMostraCheckboxPolitica(true);
                setError(true);
                setMissatgeError(respostaJson.info);
                setIdus(respostaJson.data);
            }
        })
        .catch(function (error) {
            setError(true);
            console.log(error);
            setLoading(false);
            setMissatgeError("Usuari o contrasenya incorrectes");
        });
    };

    const handleAcceptarPolitica = () => {
        setLoading(true);

        fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/activa-usuari/${idus}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accepta: acceptaPolitica })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Resposta de conexió incorrecta');
            }
            return response.json();
        })
        .then(data => {
            if (data.status === 'success') {
                ferLogin();
            } else {
                setMissatgeError(data.info || 'No s\'ha pogut acceptar les condicions');
            }
            setLoading(false);
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(false);
            setError(true);
            setMissatgeError('Error processant la petició');
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (mostraCheckboxPolitica && !acceptaPolitica) {
            setMissatgeError('Has de llegir i acceptar les condicions d\'ús i la política de privacitat per poder iniciar sessió.');
            return;
        } else if (mostraCheckboxPolitica & acceptaPolitica) {
            handleAcceptarPolitica();
        } else {
            ferLogin();
        }
    };

    return (
        <main className='login-main'>
            <div className='login'>
                <div className='contingut-login'>
                    <div className='login-container'>
                        <div class="login-title">
                            <h1>INICI DE SESSIÓ</h1>
                        </div>
                        <Form onSubmit={onSubmit} method='post'>
                            <Form.Group controlId="formBasicEmail" className='campsLogin'>
                                <Form.Label><i la clase="bi bi-envelope-fill"></i> Correu electrònic:</Form.Label>
                                <Form.Control type="email" placeholder="correu@exemple.com" value={mail} onChange={(e) => setMail(e.target.value)} />
                            </Form.Group>

                            <Form.Group controlId="formBasiccontrasenya" className='campsLogin'>
                                <Form.Label><i la clase="bi bi-lock-fill"></i> Contrasenya:</Form.Label>
                                <div class="d-flex">
                                    <Form.Control type={mostrarContrasenya ? "text" : "password"} placeholder="Contrasenya" value={contrasenya} onChange={(e) => setContrasenya(e.target.value)} name="contrasenya" required autoComplete="current-contrasenya" />
                                    <Button id='mostrarContrasenya-boto' variant="secondary" className="ms-2 d-inline-block" onClick={handleToggleContrasenya}>
                                        {mostrarContrasenya ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                 <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                                 <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                                             </svg>  : 
                                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                 <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                                 <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                                 <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                                             </svg>}
                                    </Button>
                                </div>
                            </Form.Group>

                            {mostraCheckboxPolitica && (
                                <div class="pol-us-accept">
                                    <Form.Group class="mb-3" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label={
                                            <span>
                                                He llegit i accepto les <Link as={NavLink} to="/privacitat" target="_blank" rel="noopener noreferrer">política de privacitat</Link> y <Link as={NavLink} to="/condicions" target="_blank" rel="noopener noreferrer">condicions</Link>
                                            </span>
                                        } checked={acceptaPolitica} onChange={(e) => setAcceptaPolitica(e.target.checked)} />
                                    </Form.Group>
                                </div>
                            )}
                             <Button variant="primary" type="submit" className="login-button col-12" disabled={loading || (mostraCheckboxPolitica && !acceptaPolitica)}>
                                 {loading ? <Spinner animation="border" size="sm" /> : 'Iniciar sessió'}
                             </Button>
                        </Form>
                        <div className="infologin">
                        {error && <div id='alertes-modals'>{missatgeError}</div>}
                        {loading && <Alert variant="info">Autenticant...</Alert>}
                        </div>
                    </div>
                </div>
                <div class="imatgelogin">
                    <img src={logo} alt="imatge"/>
                </div>
            </div>
        </main>
    );
}
