import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { ListGroup, Row, Col, Spinner, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import 'moment/locale/ca'; // Importa el localitzador en català
import 'react-big-calendar/lib/css/react-big-calendar.css';
import NotesBombero from './NotesBombero.jsx';
import NotesBomberoAfegeix from './NotesBomberoAfegeix.jsx';
import { NavLink, Link } from 'react-router-dom';
import CercarTurno from './CercarTurno.jsx';
import TornsExtres from './TornsExtres.jsx';

// Defineix el localizer amb moment.js en català
const localizer = momentLocalizer(moment);

// Configura el localitzador en català
moment.locale('ca');

export default function Calendari(props) {
  const token = props.api_token; 
  const usuari_id = props.usuari_id;
  const usuari_nom = props.usuari_nom;
  const [parqueUsuario, setParqueUsuario] = useState(''); 
  const [events, setEvents] = useState([]);
  const [notesCalendari, setNotesCalendari] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);

  const [eventsDiaAnterior, setEventsDiaAnterior] = useState([]);
  const [eventsDiaActual, setEventsDiaActual] = useState([]);
  const [eventsDiaSiguiente, setEventsDiaSiguiente] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false); // Nuevo estado para controlar si los datos ya se han cargado

  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, 'dddd', culture),
  };

  // Esta función asume que tienes una función que puede obtener la letra basada en la fecha
  // const getLetterForDate = (date) => {
  //   const referenceDate = new Date('2024-01-01');
  //   const daysDifference = Math.floor((date - referenceDate) / (1000 * 60 * 60 * 24)); // Diferencia en días

  //   const letters = ['B', 'C', 'D', 'E', 'F', 'G', 'A']; // Orden según el código PHP
  //   const letterIndex = Math.floor(daysDifference / 6) % letters.length; // Cálculo del índice
  //   return letters[letterIndex];
  // };

  const getLetterForDate = async (date) => {
    // Convertir el date a un string en formato YYYY-MM-DD
    const formattedDate = date.toISOString().split('T')[0];
    try {
      const response = await fetch(
         `https://aeroguardias.es/aeroguardiasapi/public/api/getlletra/${formattedDate}`,
         {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         }
      );
      const data = await response.json();
      if (data.data && data.data.lletra) {
          return data.data.lletra;
        } else {
           console.error(
              "La lletra no està disponible en la resposta de la API:",
              data
           );
           throw new Error("La lletra no està disponible");
        }
    } catch (error) {
      console.error('Error al obtenir la lletra:', error);
      throw error;
    }
  };

  const fetchLetterAndRole = async (date, userId, token) => {
    const letter = await getLetterForDate(date);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      // Obtener el grupo del bombero
      const userResponse = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/usuari/${userId}`, { headers });
      const userData = await userResponse.json();
      if (!userResponse.ok || userData.status !== "success") {
        throw new Error('No s\'ha pogut obtenir la informació del grup de l\'usuari.');
      }
      const userGroup = userData.data.grup;

      // Obtener los roles de la letra
      const rolesResponse = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/lletresirols`, { headers });
      const rolesData = await rolesResponse.json();
      if (!rolesResponse.ok || rolesData.status !== "success") {
        throw new Error('No s\'ha pogut obtenir la informació dels rols.');
      }

      // Encuentra el rol del bombero basado en el grupo y la letra del día
      const roleEntry = rolesData.data.find(entry => entry[letter.toLowerCase()] === parseInt(userGroup));
      return roleEntry ? roleEntry.rol : 'Rol no trobat';
    } catch (error) {
      console.error('Error al buscar el rol:', error);
      // Dependiendo de la estructura de tu aplicación, puedes querer manejar el error de forma diferente.
      throw error;
    }
  };
  
  const handleEventSelect = async (selectedEvent) => {
    setSelectedEvent(selectedEvent);

    const eventDate = new Date(selectedEvent.start);
    const prevDate = new Date(eventDate);
    prevDate.setDate(eventDate.getDate() - 1);
    const nextDate = new Date(eventDate);
    nextDate.setDate(eventDate.getDate() + 1);

    // Cargar todos los datos de eventos antes de mostrar el modal
    try {
      const [eventsPrev, eventsCurrent, eventsNext] = await Promise.all([
        fetchEventsForDate(moment(prevDate).format('YYYY-MM-DD')),
        fetchEventsForDate(moment(eventDate).format('YYYY-MM-DD')),
        fetchEventsForDate(moment(nextDate).format('YYYY-MM-DD'))
      ]);
      setEventsDiaAnterior(eventsPrev);
      setEventsDiaActual(eventsCurrent);
      setEventsDiaSiguiente(eventsNext);
      setShowModal(true);
    } catch (error) {
      console.error('Error al carregar tots els events:', error);
      setError(error.message);
    }
  };

  const fetchEventsForDate = async (date) => {
    try {
      const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/turnos/data/${date}/${parqueUsuario}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const json = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (json.status === "success" && json.data) {
         const letter = await getLetterForDate(new Date(date)); // Obtener la letra para el día

         const rolesResponse = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/lletresirols`,
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         const rolesData = await rolesResponse.json();
         if (!rolesResponse.ok || rolesData.status !== "success") {
            throw new Error("No se ha pogut obtenir l'informació dels rols.");
         }
        const fetchedEvents = json.data
          .map((turno) => {
               const roleEntry = rolesData.data.find(
                  (entry) =>
                     entry[letter.toLowerCase()] === parseInt(turno.grup)
               );
              const rol = roleEntry ? roleEntry.rol : "Rol no trobat";
               
              const title = turno.visible === 0 ? "OCULT PER L'USUARI" : turno.nom;

               if (turno.rol === "capataz") {
                  return {
                     title:
                        turno.visible === 0 ? (
                           <i>OCULT PER L'USUARI</i>
                        ) : (
                           turno.nom
                        ),
                     start: new Date(turno.data + "T00:00:00"),
                     end: new Date(turno.data + "T23:59:59"),
                     allDay: true,
                     description: "Capataz",
                     tipus: turno.tipus,
                  };
               } else {
                  return {
                     title:
                        turno.visible === 0 ? (
                           <i>OCULT PER L'USUARI</i>
                        ) : (
                           turno.nom
                        ),
                     start: new Date(turno.data + "T00:00:00"),
                     end: new Date(turno.data + "T23:59:59"),
                     allDay: true,
                     description: `${rol}`,
                     tipus: turno.tipus,
                  };
               }
          });
         return fetchedEvents;
      } else {
        throw new Error('Informació no trobada');
      }
    } catch (err) {
      setError(err.message);
      console.error("Error al obtenir els events:", err);
      return [];
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const rolePriority = {
    "Conductor 1ª": 1,
    "Ayudante 1ª": 2,
    "Conductor 2ª": 3,
    "Ayudante 2ª": 4,
    "Ayudante 3ª": 5,
    "Comodin": 6,
    "REVISAR CANVI": 7
  };

  const renderModalContent = () => {
    if (!selectedEvent){
      return null;
    } 
    else if (selectedEvent.title === '📝 Nota'){
      return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='notes-container'>
              <div className='nota'>
                <div className={selectedEvent.start ? 'nota-data' : 'nota-sense-data'}>
                  <h5>{selectedEvent.start ? `Nota per dia: ${moment(selectedEvent.start).format('LL')}` : 'Nota'}</h5>
                </div>
                <div className='nota-text'>
                  <p>{selectedEvent.description}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Tancar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    else if (selectedEvent.title === 'Baja'){
      return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='modal-baja'>
                <h4 className='modal-titol-turnos'>{selectedEvent.title} - {moment(selectedEvent.start).format('LL')}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='info-turnos'>
              <div className='info-turno-companys'>
                <h5>Bombers del dia seleccionat:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaActual && eventsDiaActual
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia anterior:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaAnterior && eventsDiaAnterior
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia següent:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaSiguiente && eventsDiaSiguiente
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Tancar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    else if (selectedEvent.title === 'Formación'){
      return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='modal-formacion'>
                <h4 className='modal-titol-turnos'>{selectedEvent.title} - {moment(selectedEvent.start).format('LL')}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='info-turnos'>
              <div className='info-turno-companys'>
                <h5>Bombers del dia seleccionat:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                  {eventsDiaActual && eventsDiaActual
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia anterior:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaAnterior && eventsDiaAnterior
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia següent:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaSiguiente && eventsDiaSiguiente
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Tancar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
      else if (selectedEvent.title === 'A.P.'){
      return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='modal-ap'>
                <h4 className='modal-titol-turnos'>{selectedEvent.title} - {moment(selectedEvent.start).format('LL')}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='info-turnos'>
              <div className='info-turno-companys'>
                <h5>Bombers del dia seleccionat:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                  {eventsDiaActual && eventsDiaActual
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia anterior:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaAnterior && eventsDiaAnterior
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia següent:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaSiguiente && eventsDiaSiguiente
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Tancar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    else if (selectedEvent.title === 'Vacaciones'){
      return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='modal-vacacions'>
                <h4 className='modal-titol-turnos'>{selectedEvent.title} - {moment(selectedEvent.start).format('LL')}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='info-turnos'>
              <div className='info-turno-companys'>
                <h5>Bombers del dia seleccionat:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaActual && eventsDiaActual
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia anterior:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaAnterior && eventsDiaAnterior
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia següent:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaSiguiente && eventsDiaSiguiente
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 'company'))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Tancar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    else {
      return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='modal-turno'>
                <h4 className='modal-titol-turnos'>{selectedEvent.title} - {moment(selectedEvent.start).format('LL')}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='info-turnos'>
              <div>
                <h4 id='titolturno'>El teu ROL del turno: </h4>
                <h4 id='titolturno'>{selectedEvent.description}</h4>
              </div>
              <div className='info-turno-companys'>
                <h5 id='titolturno'>Companys del dia seleccionat:</h5>
              </div>
              <div className='grup-companys-turnos'>
              <table className='companys-turnos'>
              <thead>
                <tr>
                  <th>NOM</th>
                  <th>ROL</th>
                </tr>
              </thead>
              <tbody>
                {eventsDiaActual && eventsDiaActual
                  .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                  .sort((a, b) => {
                    const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                    const rolA = getRol(a);
                    const rolB = getRol(b);
                    return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                  })
                  .map((e, idx) => (
                    <tr 
                      key={idx} 
                      className={
                        e.tipus === "C" ? 'cambio' : 
                        (e.tipus === "F" ? 'formacion' : 
                        (e.tipus === "N" ? 'no-viene' : 'company'))
                      }
                    >
                      <td>
                        {e.title}
                        {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                      </td>
                      <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                    </tr>
                  ))
                }
              </tbody>
            </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia anterior:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsDiaAnterior && eventsDiaAnterior
                  .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                  .sort((a, b) => {
                    const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                    const rolA = getRol(a);
                    const rolB = getRol(b);
                    return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                  })
                  .map((e, idx) => (
                    <tr 
                      key={idx} 
                      className={
                        e.tipus === "C" ? 'cambio' : 
                        (e.tipus === "F" ? 'formacion' : 
                        (e.tipus === "N" ? 'no-viene' : 
                        (e.description === selectedEvent.description ? 'relevo' : 'company')))
                      }
                    >
                      <td>
                        {e.title}
                        {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                        {e.description === selectedEvent.description && e.tipus === "D" && <><br /><h6>(RELEVO CONFIRMAT)</h6></>}
                      </td>
                      <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                    </tr>
                  ))
                }
                  </tbody>
                </table>
              </div>
              <div className='info-turno-companys'>
                <h5>Bombers del dia següent:</h5>
              </div>
              <div className='grup-companys-turnos'>
                <table className='companys-turnos'>
                  <thead>
                    <tr>
                      <th>NOM</th>
                      <th>ROL</th>
                    </tr>
                  </thead>
                  <tbody>
                  {eventsDiaSiguiente && eventsDiaSiguiente
                    .filter(e => e.title !== usuari_nom && (e.tipus === "D" || e.tipus === "F" || e.tipus === "C" || e.tipus === "N"))
                    .sort((a, b) => {
                      const getRol = (event) => event.tipus === "F" ? "FORMACION" : (event.tipus === "C" ? "REVISAR CANVI" : event.description);
                      const rolA = getRol(a);
                      const rolB = getRol(b);
                      return (rolePriority[rolA] || 99) - (rolePriority[rolB] || 99);
                    })
                    .map((e, idx) => (
                      <tr 
                        key={idx} 
                        className={
                          e.tipus === "C" ? 'cambio' : 
                          (e.tipus === "F" ? 'formacion' : 
                          (e.tipus === "N" ? 'no-viene' : 
                          (e.description === selectedEvent.description ? 'relevo' : 'company')))
                        }
                      >
                        <td>
                          {e.title}
                          {e.tipus === "N" && <><br /><h6>(HA FET CANVI)</h6></>}
                          {e.description === selectedEvent.description && e.tipus === "D" && <><br /><h6>(RELEVO CONFIRMAT)</h6></>}
                        </td>
                        <td>{e.tipus === "F" ? "FORMACION" : (e.tipus === "C" ? "REVISAR CANVI" : e.description)}</td>                      
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Tancar</Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  const eventPropGetter = (event) => {
    const style = {};

    if (event.title.includes("Vacaciones")) {
      style.backgroundColor = '#10B981'; // Color diferente para eventos "Vacaciones"
    } 
    else if (event.title.includes("Baja")) {
      style.backgroundColor = '#DC2626'; // Color diferente
    }
    else if (event.title.includes("Turno")) {
      style.backgroundColor = '#3B82F6'; // Color estándar para otros eventos
    }
    else if (event.title.includes("Formación")) {
      style.backgroundColor = 'rgb(135 36 251)'; // Color diferente para eventos "Nota"
    }
    else if (event.title.includes("A.P.")){
      style.backgroundColor = "#ff6d1f"; // Color diferente para eventos "Nota"
    }
    else{
      style.backgroundColor = '#F5F5DB'; // Color estándar para otros eventos
      style.color = '#000'
    }

    return { style };
  };

  const EXPIRATION_TIME = 5 * 60 * 1000; // 30 minutos en milisegundos

  const fetchNotes = async () => {
    try {
        const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/notes/usuari/${usuari_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        if (data.data) {
            const noteEvents = data.data.map(note => ({
                title: '📝 Nota',
                start: new Date(note.data),
                end: new Date(note.data),
                allDay: true,
                description: note.nota
            }));
            setNotesCalendari(noteEvents);
            saveToLocalStorage('notesCalendari', noteEvents); // Guardar notas en localStorage
        }
    } catch (error) {
        console.error('Error en descarregar les notes:', error);
    }
  };
  

  const fetchParqueUsuario = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    try {
      const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/usuari/${usuari_id}`, { headers });
      const data = await response.json();
      if (!response.ok || data.status !== "success") {
        throw new Error('No s\'ha pogut obtenir la informació del parc de l\'usuari.');
      }
      setParqueUsuario(data.data.parque);
    } catch (error) {
      console.error('Error al buscar el parc:', error);
      setError(error.message);
    }
  };

  const saveToLocalStorage = (key, data) => {
    const item = {
      data: data,
      timestamp: new Date().getTime()
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const loadFromLocalStorage = (key, expirationTime) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    if (now - item.timestamp > expirationTime) {
      localStorage.removeItem(key);
      return null;
    }
    return item.data;
  };

  const datadmodturnos = async () => {
      try {
        const response = await fetch(
            `https://aeroguardias.es/aeroguardiasapi/public/api/turnosdmod`,
            {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
              },
            }
        );
        const data = await response.json();
        if (data.status === "success" && data.data) {
            setLastUpdate(moment(data.data).format("LLL"));
        } else {
            setLastUpdate("Mai");
        }
      } catch (error) {
        console.error("Error:", error);
      }
  };

  // useEffect(() => {
  //   const eventsFromStorage = loadFromLocalStorage('events', EXPIRATION_TIME);
  //   const notesFromStorage = loadFromLocalStorage('notesCalendari', EXPIRATION_TIME);
    
  //   if (eventsFromStorage && notesFromStorage) {
  //     datadmodturnos();
  //     fetchParqueUsuario();
  //     setEvents(eventsFromStorage.filter(event => event.title !== "No-Viene")); 
  //     setNotesCalendari(notesFromStorage);
  //     setDataLoaded(true);
  //     setLoading(false);
  //   } 
  //   else if (eventsFromStorage && !notesFromStorage) {
  //     datadmodturnos();
  //     fetchParqueUsuario();
  //     setEvents(eventsFromStorage.filter(event => event.title !== "No-Viene")); 
  //     fetchNotes();
  //     const nfs = loadFromLocalStorage('notesCalendari', EXPIRATION_TIME);
  //     setNotesCalendari(nfs);
  //     setDataLoaded(true); // Actualiza el estado para indicar que los datos se han cargado
  //     setLoading(false);
  //   }
  //   else {
  //     const fetchEventsUsuari = async () => {
  //       try {
  //         const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/turnos/usuari/${usuari_id}`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             'Content-Type': 'application/json'
  //           }
  //         });
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         const json = await response.json();
  //         if (json.status === "success" && json.data) {
  //           const fetchedEvents = await Promise.all(json.data.map(async (turno) => {
  //             const rol = await fetchLetterAndRole(new Date(turno.data), usuari_id, token);
  //             return {
  //               title: turno.activitat,
  //               start: new Date(turno.data + 'T00:00:00'),
  //               end: new Date(turno.data + 'T23:59:59'),
  //               allDay: true,
  //               description: rol
  //             };
  //           }));
  //           setEvents(fetchedEvents.filter(event => event.title !== "No-Viene")); // Filtra los eventos de tipus "N"
  //           saveToLocalStorage('events', fetchedEvents); // Guardar eventos en localStorage
  //         } else {
  //           throw new Error('No data found');
  //         }
  //       } catch (err) {
  //         setError(err.message);
  //         console.error("Error obtenint els events:", err);
  //       }
  //     };
  
  //     const loadData = async () => {
  //       await fetchParqueUsuario();
  //       await datadmodturnos();
  //       await fetchEventsUsuari();
  //       await fetchNotes();
  //       setDataLoaded(true); // Actualiza el estado para indicar que los datos se han cargado
  //       setLoading(false);
  //     };
  
  //     loadData();
  //   }
  // }, [usuari_id, token, dataLoaded]); // Agregar dataLoaded a las dependencias
  useEffect(() => {
  const eventsFromStorage = loadFromLocalStorage('events', EXPIRATION_TIME);
  const notesFromStorage = loadFromLocalStorage('notesCalendari', EXPIRATION_TIME);

    const loadData = async () => {
       setLoading(true); // Mover esto aquí para asegurarse de que solo se ejecute después de cargar los datos
       if (eventsFromStorage && notesFromStorage) {
          await Promise.all([datadmodturnos(), fetchParqueUsuario()]);
          setEvents(
             eventsFromStorage.filter((event) => event.title !== "No-Viene")
          );
          setNotesCalendari(notesFromStorage);
          setDataLoaded(true);
          setLoading(false); // Mover esto aquí para asegurarse de que solo se ejecute después de cargar los datos
       } else if (eventsFromStorage && !notesFromStorage) {
          await Promise.all([
             datadmodturnos(),
             fetchParqueUsuario(),
             fetchNotes(),
          ]);
          const nfs = loadFromLocalStorage("notesCalendari", EXPIRATION_TIME);
          setNotesCalendari(nfs);
          setEvents(
             eventsFromStorage.filter((event) => event.title !== "No-Viene")
          );
          setDataLoaded(true); // Actualiza el estado para indicar que los datos se han cargado
          setLoading(false); // Mover esto aquí para asegurarse de que solo se ejecute después de cargar los datos
       } else {
          const fetchEventsUsuari = async () => {
             try {
                const response = await fetch(
                   `https://aeroguardias.es/aeroguardiasapi/public/api/turnos/usuari/${usuari_id}`,
                   {
                      headers: {
                         Authorization: `Bearer ${token}`,
                         "Content-Type": "application/json",
                      },
                   }
                );
                if (!response.ok) {
                   throw new Error(`HTTP error! status: ${response.status}`);
                }
                const json = await response.json();
                if (json.status === "success" && json.data) {
                   const fetchedEvents = await Promise.all(
                      json.data.map(async (turno) => {
                         const rol = await fetchLetterAndRole(
                            new Date(turno.data),
                            usuari_id,
                            token
                         );
                         return {
                            title: turno.activitat,
                            start: new Date(turno.data + "T00:00:00"),
                            end: new Date(turno.data + "T23:59:59"),
                            allDay: true,
                            description: rol,
                         };
                      })
                   );
                   setEvents(
                      fetchedEvents.filter(
                         (event) => event.title !== "No-Viene"
                      )
                   ); // Filtra los eventos de tipus "N"
                   saveToLocalStorage("events", fetchedEvents); // Guardar eventos en localStorage
                } else {
                   throw new Error("No data found");
                }
             } catch (err) {
                setError(err.message);
                console.error("Error obtenint els events:", err);
             }
          };

          await Promise.all([
             fetchParqueUsuario(),
             datadmodturnos(),
             fetchEventsUsuari(),
             fetchNotes(),
          ]);
          setDataLoaded(true); // Actualiza el estado para indicar que los datos se han cargado
       }
       setLoading(false); // Mover esto aquí para asegurarse de que solo se ejecute después de cargar los datos
    };

  loadData();
}, [usuari_id, token, dataLoaded]); // Agregar dataLoaded a las dependencias


  const createICSFile = () => {
    let icsContent = 'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Your Company//EN\nCALSCALE:GREGORIAN\n';
    
    events.forEach(event => {
        const formattedStart = moment(event.start).format('YYYYMMDDTHHmmss');
        const formattedEnd = moment(event.end).format('YYYYMMDDTHHmmss');
        
      // Si event.title es diferente a "Turno", no mostrar event.description
      if (event.title === 'Turno' || event.title === 'Turno-Cambio') {
        icsContent += `BEGIN:VEVENT\nSUMMARY:${event.title}- ${event.description}\nDTSTART:${formattedStart}\nDTEND:${formattedEnd}\nEND:VEVENT\n`;
      }
      else {
        icsContent += `BEGIN:VEVENT\nSUMMARY:${event.title}\nDTSTART:${formattedStart}\nDTEND:${formattedEnd}\nEND:VEVENT\n`;
      }
    });

    icsContent += 'END:VCALENDAR';

    const blob = new Blob([icsContent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'CalendariAeroguardias.ics';
    link.click();
  };

  const tot = events.concat(notesCalendari);
  // Filtrar por event.title !== "No-Viene"));

  // Funció recarregar calendari que esborra events del localstorage i recarrega la pagina
const recarregarCalendari = () => {
   localStorage.removeItem("events");
   window.location.reload();
};

  return (
     <main>
        <div className="calendari-container">
           <hr />
           <div className="header-calendari">
              <div id="titol-calendari">
                 <h1>Calendari 🗓️</h1>
              </div>
              <div id="botons-calendari">
                 <Button
                    className="botoNormal"
                    variant="secondary"
                    onClick={recarregarCalendari}
                 >
                    <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       fill="currentColor"
                       class="bi bi-arrow-clockwise"
                       viewBox="0 0 16 16"
                    >
                       <path
                          fill-rule="evenodd"
                          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                       />
                       <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                    </svg>
                    Refrescar calendari
                 </Button>
                 <Button
                    className="botoN-curt"
                    variant="secondary"
                    onClick={recarregarCalendari}
                 >
                    <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       fill="currentColor"
                       class="bi bi-arrow-clockwise"
                       viewBox="0 0 16 16"
                    >
                       <path
                          fill-rule="evenodd"
                          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                       />
                       <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                    </svg>
                    Refrescar
                 </Button>
                 <Button
                    className="botoNormal"
                    variant="success"
                    type="button"
                    onClick={createICSFile}
                 >
                    <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       fill="currentColor"
                       class="bi bi-cloud-download-fill"
                       viewBox="0 0 16 16"
                    >
                       <path
                          fill-rule="evenodd"
                          d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0m-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708z"
                       />
                    </svg>
                    Exportar calendari
                 </Button>
                 <Button
                    className="botoN-curt"
                    variant="success"
                    type="button"
                    onClick={createICSFile}
                 >
                    <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       fill="currentColor"
                       class="bi bi-cloud-download-fill"
                       viewBox="0 0 16 16"
                    >
                       <path
                          fill-rule="evenodd"
                          d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0m-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708z"
                       />
                    </svg>
                    Exportar
                 </Button>
              </div>
           </div>
           <hr />
           {loading ? (
              <div className="spinner-excels">
                 <span className="ml-2">
                    Carregant calendari, per favor espereu, no recarregueu la pàgina...
                 </span>
                 <Spinner animation="border" variant="secondary" />
              </div>
           ) : (
              <div className="calendari">
                 <Calendar
                    localizer={localizer}
                    events={tot}
                    startAccessor="start"
                    endAccessor="end"
                    formats={formats}
                    eventPropGetter={eventPropGetter}
                    onSelectEvent={handleEventSelect}
                    views={["month", "week", "day"]}
                    messages={{
                       allDay: "Tot el dia",
                       next: "Següent",
                       previous: "Anterior",
                       today: "Avui",
                       month: "Mes",
                       week: "Setmana",
                       day: "Dia",
                       date: "Data",
                       time: "Hora",
                       event: "Esdeveniment",
                    }}
                 />
              </div>
           )}
           <div className="turnos-upd">
              {/* Mostrar darrera actualització del calendari */}
              <p>Darrera actualització:</p>
              <p>{lastUpdate}</p>
           </div>
           <hr />
           <NotesBomberoAfegeix token={token} usuari_id={usuari_id} />
           <hr />
           <NotesBombero token={token} usuari_id={usuari_id} />
           <hr />
           <TornsExtres
              token={token}
              usuari_id={usuari_id}
              parqueUsuario={parqueUsuario}
           />
           <hr />
           <CercarTurno token={token} usuari_id={usuari_id} />
           <hr />
           {renderModalContent()}
        </div>
     </main>
  );
}
