import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function UsuariDadesProfessionalsForm(props) {
    const [dVacacions, setDVacacions] = useState('');
    const [nAp, setNAp] = useState('');
    const [grup, setGrup] = useState('');
    const [parque, setParque] = useState('');
    const [rol, setRol] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [mostrarMissatgeconf, setMostrarmissatgeconf] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const id = props.usuari_id;
    const token = props.api_token;
    const Navigate = useNavigate();

    useEffect(() => {
        if (error !== '') {
            setShowError(true); // Muestra el alerta de error
            const timer = setTimeout(() => {
                setShowError(false); // Oculta el alerta después de 4 segundos
                setError(''); // Limpia el mensaje de error
            }, 4000);
            return () => clearTimeout(timer); // Limpia el timer si el componente se desmonta o si error cambia antes de que pase el tiempo
        }

        if (confirmationMessage !== '') {
            setMostrarmissatgeconf(true); // Muestra el mensaje de confirmación
            const timer = setTimeout(() => {
                setMostrarmissatgeconf(false); // Oculta el mensaje de confirmación después de 4 segundos
                setConfirmationMessage(''); // Limpia el mensaje de confirmación después de 4 segundos
            }, 4000);
            return () => clearTimeout(timer); // Limpia el timer si el componente se desmonta o si confirmationMessage cambia antes de que pase el tiempo
        }

    }, [error, confirmationMessage]); // Dependencia al estado 'error'


    useEffect(() => {
        let isMounted = true; // Flag determinando si el componente aún está montado
    
        const fetchDadesProfessionalsUsuari = async () => {
            if (!id) return; // Sal si no hay ID definido
            setError('');
            setLoading(true);
    
            try {
                const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/usuari/${id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                const data = await response.json();
                if (!isMounted) return; // Sal si el componente se ha desmontado
    
                if (response.ok && data.status === 'success') {
                    setDVacacions(data.data.n_vacacions);
                    setNAp(data.data.n_ap);
                    setGrup(data.data.grup);
                    setParque(data.data.parque);
                    setRol(data.data.rol);
                } else {
                    throw new Error(data.message || 'Dades no trobades');
                }
            } catch (error) {
                if (isMounted) {
                    setError('Error al descarregar les dades de l\'usuari, falta algun camp.');
                    console.error('Error en descarregar les dades de l\'usuari:', error);
                }
            }
    
            if (isMounted) setLoading(false);
        };
    
        fetchDadesProfessionalsUsuari();
    
        return () => {
            isMounted = false; 
        };
    }, [id, token]);
    

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Verificar que els camps grup i parque no estiguin buits 
            if (!parque || !grup) {
                throw new Error('Els camps grup i parque no poden estar buits');
            }
            // Verificar que el camp nVacançes sigui un nombre, nAp un nombre, grup un nombre del 1 al 7 el parque que sigui nord o sud i el rol bombero o capataz
            else if ( isNaN(grup) || (parseInt(grup) < 1 || parseInt(grup) > 7) || (parque !== 'nord' && parque !== 'sud') || (rol !== 'bombero' && rol !== 'capataz')) {
                throw new Error('Els camps nº de vacacions, nº d\'assumptes personals, grup, parque o rol no són correctes');
            }
            else{
                setError('');
                const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/dades/usuari/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        grup: parseInt(grup),
                        parque: parque,
                        rol: rol
                    })
                });
                const responseData = await response.json();
                if (response.ok) {
                    setError('');
                    setConfirmationMessage('Dades professionals actualitzades! La propera vegada que recarregueu la pàgina veureu les dades actualitzades.');
                } else {
                    setError(responseData.message || 'Error al actualitzar les dades professionals');
                }
            }
        }catch (error) {
            setError('Error al guardar les dades de l\'usuari, heu de completar tots els camps.');
            console.error('Error en guardar les dades de l\'usuari:', error);
        }
        setLoading(false);
    };

    return (
        <div className='form-dades-usuari'>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group className='mb-3'>
                    <Form.Label>Grup:</Form.Label>
                    <Form.Control
                        type="number"
                        value={grup}
                        onChange={(e) => setGrup(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Parque:</Form.Label>
                    <Form.Select
                        value={parque}
                        onChange={(e) => setParque(e.target.value)}
                    >
                        <option value="">Selecciona un parc:</option>
                        <option value="nord">Nord</option>
                        <option value="sud">Sud</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Rol actual:</Form.Label>
                    <Form.Select
                        value={rol}
                        onChange={(e) => setRol(e.target.value)}
                    >
                        <option value="">Selecciona el rol</option>
                        <option value="bombero">Bombero</option>
                        <option value="capataz">Capataz</option>
                    </Form.Select>
                </Form.Group>
            <div className="botons-edicio">
                <Button type="submit" disabled={loading}>
                    Guardar
                </Button>
                <Button variant="warning" onClick={() => { window.location.reload(); }}>
                    Cancel·la
                </Button>
                    <Button variant="info" onClick={() => { props.setShowFormProfessionals(false); }}>
                    Ocultar
                </Button>
            </div>
            </Form>
            {loading && 
            <div className="spinner">
                <Spinner animation="border" />
            </div>}
            <br />
            {mostrarMissatgeconf && (
                <div className="alert alert-success" role="alert">
                    <p>{confirmationMessage}</p>
                </div>
            )}
            {showError && <Alert variant="danger">{error}</Alert>}
        </div>
    );
}
