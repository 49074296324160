            
import Ajuda from "./components/Ajuda.jsx";
import Menu from "./components/Menu.jsx";
import { HashRouter,BrowserRouter, Routes, Route , Navigate, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./components/login/Login.jsx";
import Logout from "./components/login/Logout.jsx";
import Usuari from "./components/Usuari.jsx";
import { storage } from "./utils/storage.js"; 
import Usuaris from "./components/usuaris/Usuaris.jsx";
import UsuarisCRUD from "./components/usuaris/UsuarisCRUD.jsx";
import UsuarisAfegeix from "./components/usuaris/UsuarisAfegeix.jsx";
import Notes from "./components/notes/Notes.jsx";
import NotesCRUD from "./components/notes/NotesCRUD.jsx";
import NotesAfegeix from "./components/notes/NotesAfegeix.jsx";
import Turnos from "./components/turnos/Turnos.jsx";
import TurnosCRUD from "./components/turnos/TurnosCRUD.jsx";
import TurnosAfegeix from "./components/turnos/TurnosAfegeix.jsx";
import Gastos from "./components/gastos/Gastos.jsx";
import GastosCRUD from "./components/gastos/GastosCRUD.jsx";
import GastosAfegeix from "./components/gastos/GastosAfegeix.jsx";
import Dadesusuaris from "./components/dadesusuaris/Dadesusuaris.jsx";
import DadesusuarisCRUD from "./components/dadesusuaris/DadesusuarisCRUD.jsx";
import DadesusuarisAfegeix from "./components/dadesusuaris/DadesusuarisAfegeix.jsx";
import Calendari from "./components/calendari/Calendari.jsx";
import Lletresirols from "./components/excels/Lletresirols.jsx";
import Lletresirolsgestor from "./components/excels/Lletresirolsgestor.jsx";
import PoliticaPrivacitat from "./components/condicionsapp/PoliticaPrivacitat.jsx";
import CondicionsUs from "./components/condicionsapp/CondicionsUs.jsx";
import { NavLink, Link } from 'react-router-dom';
/**
 * Component principal de l'aplicació.
 * Aquest component és responsable de renderitzar les rutes de l'aplicació utilitzant React Router.
 * Conté una ruta principal ("/") que mostra el component Menu i altres rutes com "/feines" i "/ajuda"
 * que mostren els components Feines i Ajuda respectivament. Si cap ruta coincideix, es mostra un missatge d'error 404.
 *
 * @returns {JSX.Element} El component principal de l'aplicació.
 */
function App() {
  // const [api_token, setapi_token] = useState(null);
  const [api_token, setapi_token] = useState(storage.get("api_token") || null);

  const [usuari_id, setusuari_id] = useState(null);
  const [usuari_rol, setusuari_rol] = useState(null);
  const [usuari_nom, setusuari_nom] = useState(null);
  const [turnos_visibles, setturnos_visibles] = useState(null);

  const cargarDatosUsuario = async (token) => {
    try {
      const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/usuaris/token/${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const responseData = await response.json();
      if (response.ok) {
        setusuari_id(responseData.data.id);
        setusuari_nom(responseData.data.nom);
        setusuari_rol(responseData.data.rol);
        setturnos_visibles(responseData.data.turnos_visibles);
      } else {
        throw new Error('Token inválido o expirado');
      }
    } catch (error) {
        console.error(error);
        storage.remove("api_token");
        setapi_token(null);
        // Esborram tot el del local storage
        storage.remove("events");
        storage.remove("notes");
        storage.remove("_grecaptcha");  
        window.location.href = "/login";
    }
  }

  useEffect(() => {
    if (api_token) {
      cargarDatosUsuario(api_token);
    }
  }, [api_token]);

    // useEffect(() => {
    //    const token = storage.get("api_token");
    //    if (token) {
    //       setapi_token(token);
    //       cargarDatosUsuario(token);
    //    }
    // }, []);

  // Guardam el token i l'usuari al localStorage
  const ferGuardaapi_token = (api_token) => {
    storage.set("api_token", api_token);  // guardant el api_token al localStorage
    setapi_token(api_token);
  }
  const ferGuardausuari_id = (usuari_id) => {
    setusuari_id(usuari_id);
  }
  const ferGuardausuari_rol = (usuari_rol) => {
    setusuari_rol(usuari_rol);
  }
  const ferGuardausuari_nom = (usuari_nom) => {
    setusuari_nom(usuari_nom);
  }
  
  // Si nos encontramos en la ruta / redirigir a /calendari si hay api_token

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Menu api_token={api_token} usuari_id={usuari_id} usuari_rol={usuari_rol} usuari_nom={usuari_nom}/>} > */}
        <Route path="/" element={<Menu api_token={api_token} usuari_id={usuari_id} usuari_rol={usuari_rol} usuari_nom={usuari_nom} />}>
        {/* Routes sols per a usuaris logats administradors*/}
        {api_token && usuari_rol==="administrador" && <>
            {/* USUARIS */}
            <Route path="/usuaris" element={<Usuaris api_token = {api_token}/>} />
            <Route path="/usuaris/:id" element={<UsuarisCRUD api_token={api_token}/>} />
            <Route path="/usuaris/afegir" element={<UsuarisAfegeix api_token={api_token}/>} />
            {/* NOTES */}
            <Route path="/notes" element={<Notes api_token = {api_token} usuari_nom={usuari_nom} usuari_id={usuari_id}/>} />
            <Route path="/notes/:id" element={<NotesCRUD api_token={api_token}/>} />
            <Route path="/notes/afegir" element={<NotesAfegeix api_token={api_token}/>} />
            {/* TURNOS */}
            <Route path="/turnos" element={<Turnos api_token = {api_token} usuari_nom={usuari_nom} usuari_id={usuari_id}/>} />
            <Route path="/turnos/:id" element={<TurnosCRUD api_token={api_token}/>} />
            <Route path="/turnos/afegir" element={<TurnosAfegeix api_token={api_token}/>} />
            {/* GASTOS */}
            <Route path="/gastos" element={<Gastos api_token = {api_token} usuari_nom={usuari_nom} usuari_id={usuari_id}/>} />
            <Route path="/gastos/:id" element={<GastosCRUD api_token={api_token}/>} />
            <Route path="/gastos/afegir" element={<GastosAfegeix api_token={api_token}/>} />
            {/* DADES USUARI */}
            <Route path="/dadesusuari" element={<Dadesusuaris api_token = {api_token} usuari_nom={usuari_nom} usuari_id={usuari_id}/>} />
            <Route path="/dadesusuari/:id" element={<DadesusuarisCRUD api_token={api_token}/>} />
            <Route path="/dadesusuari/afegir" element={<DadesusuarisAfegeix api_token={api_token}/>} />
            {/* EXCEL */}
            <Route path="/excel" element={<Lletresirols api_token = {api_token} />} />
        </>} 
        {/* Rutes pels usuaris Gestors */}
        {api_token && usuari_rol==="gestor" && <>
          <Route path="/excel" element={<Lletresirolsgestor api_token = {api_token} />} />
        </>}
        {/* Routes sols per a usuaris logats*/}
        {api_token && <>
            {/* DADES USUARI */}
            <Route path="/usuari" element={<Usuari api_token={api_token} usuari_nom={usuari_nom} usuari_id={usuari_id} turnos_visibles={turnos_visibles} />}/>
            {/* CALENDARI */}
            <Route path="/calendari" element={<Calendari  api_token = {api_token} usuari_nom={usuari_nom} usuari_id={usuari_id}/>}/>
        </>} 
        {/* Routes sols per a usuaris NO logats*/}
        {!api_token && <>
          <Route path="/login" element={<Login guardausuari_id={ferGuardausuari_id} guardaapi_token={ferGuardaapi_token} guardausuari_rol={ferGuardausuari_rol} guardausuari_nom={ferGuardausuari_nom}/>} />
        </> } 
        {/* Routes per a tots els usuaris*/}
          <Route path="/ajuda" element={<Ajuda />} />
          <Route path="/privacitat" element={<PoliticaPrivacitat />} />
          <Route path="/condicions" element={<CondicionsUs />} />
          <Route path="*" element={
            <main>
              <div className="notfound">
                <h1>Ups!</h1>
                <h4>Pàgina no trobada</h4>
                {/* Botó per tornar a calendari */}
                <Link as={NavLink}to="/ajuda" id="boto-notfound" className="btn btn-primary">
                  <text>Anar a Ajuda</text> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                  </svg>
                </Link>
              </div>
            </main>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
