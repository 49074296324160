import React, { useState , useEffect} from 'react';
import { useLocation, NavLink, Link, Outlet, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container, Modal } from 'react-bootstrap';
import logo from '../images/LogoAeroGuardias.png'; // Import the image
import Logout from './login/Logout.jsx';
import ModalContacte from './ModalContacte.jsx';
import { storage } from "../utils/storage.js"; 
import '../style/Style.css'; // Importa l'arxiu CSS
import '../style/BootStrap.css'; // Importa l'arxiu CSS

export default function Menu({ api_token, usuari_nom, usuari_rol, usuari_id}) {
    const [showMenu, setShowMenu] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // estat del modal
    const [modalLogoutOpen, setModalLogoutOpen] = useState(false); // Nuevo estado para controlar la visibilidad del modal de cierre de sesión
    const navigate= useNavigate();
    const id = usuari_id;
    const nom_usuari = usuari_nom || '';
    const lastSpaceIndex = nom_usuari.lastIndexOf(' ');
    const nombre = lastSpaceIndex !== -1 ? nom_usuari.substring(lastSpaceIndex + 1).toUpperCase() : '';

    // useEffect(() => {
    //     if (!api_token) {
    //         navigate('/login');
    //     }
    // }, [api_token]);

    useEffect(() => {
        const token = storage.get("api_token");

           if (!token) {
              navigate("/login");
           }
        }, []);
  
    const location = useLocation();

    // Puedes definir una función para verificar si la ruta actual es parte de los enlaces del dropdown
    const isActiveDropdown = (paths) => paths.some(path => location.pathname.includes(path));

    // Define las rutas que se consideran parte del dropdown de 'Modificar Taules'
    const adminPaths = ['/usuaris', '/dadesusuari', '/notes', '/turnos', '/gastos'];


    function collapsenav(){
        const navbarToggle = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarToggle.classList.add("collapsed");
        navbarCollapse.classList.remove("show");
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen); // Això commuta l'estat del modal
    };

    const toggleModalLogout = () => {
        setModalLogoutOpen(!modalLogoutOpen); // Añade esta función para abrir/cerrar el modal de cierre de sesión
    };

    return (
        <>
            <Navbar bg="dark" className="color-nav" variant="dark" expand="sm" sticky="top">
                <div style={{paddingLeft:'20px'}} id='imatgeheader'>
                    <img style={{ height: '80px' }}
                        src={logo} alt="Foto del header"
                    />
                </div>
                <div id='textheader'>
                    <h2>AEROGUARDIAS</h2>
                    <Nav>
                        {usuari_nom && api_token && (
                        <div className='benvinguda-usuari-container'>
                            <div className='svg-bombero'>
                            <NavDropdown  title={
                                <div className='benvinguda-usuari'>
                                    <span>Benvingut, {nombre}</span>
                                    <svg height="26" width="26" fill='#C8C8C9' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M447.954,153.151c-16.501,1.655-41.568,12.155-53.971,6.078c0,0-6.99-111.258-99.389-129.846v41.916h-11.877V9.394c0-5.188-5.026-9.394-11.211-9.394h-15.511H240.5c-6.201,0-11.227,4.207-11.227,9.394v61.904h-11.876V29.383c-92.399,18.588-99.389,129.846-99.389,129.846c-12.387,6.078-37.47-4.423-53.956-6.078c-25.794-2.59-48.171,4.972-39.588,30.944c5.908,17.916,29.615,32.916,55.919,44.46c12.41,5.451,26.042,9.743,38.978,13.137c-0.109,2.265-0.356,4.5-0.356,6.788c0.007,75.667,61.331,136.991,136.998,136.999c75.666-0.008,136.99-61.332,136.998-136.999c0-2.296-0.247-4.53-0.356-6.804c12.936-3.387,26.568-7.686,38.962-13.122c26.305-11.544,50.012-26.544,55.935-44.46C496.109,158.122,473.748,150.561,447.954,153.151zM214.581,136.697c31.377-8.792,41.414-31.385,41.414-31.385s10.052,22.593,41.428,31.385v40.168c0,0-17.567,27.612-41.428,31.377c-23.846-3.766-41.414-31.377-41.414-31.377V136.697zM120.404,230.063c-8.242-3.758-14.459-7.16-17.753-9.488c-11.335-8.01-4.763-15.279,14.088-5.327c1.91,1.005,3.835,1.986,5.753,2.961C121.61,222.098,120.953,226.057,120.404,230.063zM333.278,325.756c-19.818,19.802-47.05,32.003-77.275,32.011c-30.225-0.008-57.458-12.209-77.276-32.011c-19.802-19.817-32.003-47.05-32.01-77.276c0-6.34,0.68-12.51,1.724-18.542c29.653,11.714,62.916,18.82,107.554,18.82c44.646,0,77.916-7.106,107.577-18.82c1.044,6.032,1.716,12.194,1.716,18.542C365.28,278.705,353.079,305.938,333.278,325.756zM409.354,220.575c-3.294,2.328-9.511,5.73-17.753,9.488c-0.542-4.006-1.199-7.972-2.08-11.862c1.918-0.967,3.834-1.948,5.745-2.953C414.117,205.296,420.674,212.564,409.354,220.575z"/>
                                            <circle class="st0" cx="213.445" cy="269.682" r="12.866"/>
                                            <circle class="st0" cx="298.56" cy="269.682" r="12.866"/>
                                            <path class="st0" d="M163.07,384.226c-78.319,16.484-74.198,82.432-74.198,82.432C142.456,507.879,244.961,512,244.961,512V410.384C185.207,407.809,163.07,384.226,163.07,384.226z"/>
                                            <path class="st0" d="M348.919,384.226c0,0-22.137,23.583-81.89,26.158V512c0,0,102.505-4.121,156.088-45.342C423.117,466.658,427.238,400.711,348.919,384.226z"/>
                                        </g>
                                    </svg>
                                </div>
                            } id="desplegable">
                                <NavDropdown.Item as={NavLink} to="/usuari">Les meves dades</NavDropdown.Item>
                                <NavDropdown.Item onClick={toggleModalLogout}>Tancar sessió</NavDropdown.Item>
                            </NavDropdown>
                            </div>
                        </div>
                        )}
                        {!api_token && <>
                            <div className='benvinguda-usuari-container'>
                                <div className='login-container-text'>
                                    <NavLink onClick={collapsenav} className="nav-link" to="/login" activeClassName="active">
                                        Iniciar<br/>sessió
                                    </NavLink>
                                </div>
                            </div>
                        </>}
                    </Nav>
                </div>
                <Navbar.Brand onClick={toggleMenu} ></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className={showMenu ? "show" : ""} >
                    <Nav className="mr-auto align-items-center">
                        {api_token && <>
                            <NavLink onClick={collapsenav} className="nav-link" to="/calendari" activeClassName="active">Calendari</NavLink>
                        </>}   
                        {api_token && usuari_rol === 'administrador' && <>
                        <NavDropdown title="Modificar Taules" id="desplegable" className={isActiveDropdown(adminPaths) ? 'active-dropdown' : ''}>
                            <NavDropdown.Item onClick={collapsenav} as={NavLink} to="/usuaris">Usuaris</NavDropdown.Item>
                            <NavDropdown.Item onClick={collapsenav} as={NavLink} to="/dadesusuari">Dades Usuaris</NavDropdown.Item>
                            <NavDropdown.Item onClick={collapsenav} as={NavLink} to="/notes">Notes</NavDropdown.Item>
                            <NavDropdown.Item onClick={collapsenav} as={NavLink} to="/turnos">Turnos</NavDropdown.Item>
                            <NavDropdown.Item onClick={collapsenav} as={NavLink} to="/gastos">Gastos</NavDropdown.Item>
                        </NavDropdown>

                            <NavLink onClick={collapsenav} className="nav-link" to="/excel" activeClassName="active">Administració</NavLink>
                        </>}
                        {api_token && usuari_rol === 'gestor' && <>
                            <NavLink onClick={collapsenav} className="nav-link" to="/excel" activeClassName="active">Administració</NavLink>
                        </>}
                        {/* <NavLink onClick={collapsenav} className="nav-link" to="/ajuda" activeClassName="active">Ajuda</NavLink> */}
                        <NavLink onClick={collapsenav} className="nav-link" to="/ajuda" activeClassName="active">Ajuda</NavLink>
                        {!api_token && <>
                            <div id='loginweb' className='benvinguda-usuari-container'>
                                <NavLink onClick={collapsenav} className="nav-link" to="/login" activeClassName="active">
                                    Iniciar sessió
                                </NavLink>
                            </div>
                        </>}
                    </Nav>
                    <Nav>
                        <button id='contacta'
                            onClick={toggleModal}>
                            Contactar
                        </button>
                    </Nav>
                </Navbar.Collapse>
                <ModalContacte isOpen={modalOpen} onClose={toggleModal} />
            <Navbar.Collapse className="justify-content-end">
            <Nav>
                {usuari_nom && api_token && (
                <div className='benvinguda-usuari-container-normal'>
                    <NavDropdown  title={
                        <div className='benvinguda-usuari'>
                            <span>Benvingut, {nombre}</span>
                            <svg height="26" width="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <g>
                                    <path class="st0" d="M447.954,153.151c-16.501,1.655-41.568,12.155-53.971,6.078c0,0-6.99-111.258-99.389-129.846v41.916h-11.877V9.394c0-5.188-5.026-9.394-11.211-9.394h-15.511H240.5c-6.201,0-11.227,4.207-11.227,9.394v61.904h-11.876V29.383c-92.399,18.588-99.389,129.846-99.389,129.846c-12.387,6.078-37.47-4.423-53.956-6.078c-25.794-2.59-48.171,4.972-39.588,30.944c5.908,17.916,29.615,32.916,55.919,44.46c12.41,5.451,26.042,9.743,38.978,13.137c-0.109,2.265-0.356,4.5-0.356,6.788c0.007,75.667,61.331,136.991,136.998,136.999c75.666-0.008,136.99-61.332,136.998-136.999c0-2.296-0.247-4.53-0.356-6.804c12.936-3.387,26.568-7.686,38.962-13.122c26.305-11.544,50.012-26.544,55.935-44.46C496.109,158.122,473.748,150.561,447.954,153.151zM214.581,136.697c31.377-8.792,41.414-31.385,41.414-31.385s10.052,22.593,41.428,31.385v40.168c0,0-17.567,27.612-41.428,31.377c-23.846-3.766-41.414-31.377-41.414-31.377V136.697zM120.404,230.063c-8.242-3.758-14.459-7.16-17.753-9.488c-11.335-8.01-4.763-15.279,14.088-5.327c1.91,1.005,3.835,1.986,5.753,2.961C121.61,222.098,120.953,226.057,120.404,230.063zM333.278,325.756c-19.818,19.802-47.05,32.003-77.275,32.011c-30.225-0.008-57.458-12.209-77.276-32.011c-19.802-19.817-32.003-47.05-32.01-77.276c0-6.34,0.68-12.51,1.724-18.542c29.653,11.714,62.916,18.82,107.554,18.82c44.646,0,77.916-7.106,107.577-18.82c1.044,6.032,1.716,12.194,1.716,18.542C365.28,278.705,353.079,305.938,333.278,325.756zM409.354,220.575c-3.294,2.328-9.511,5.73-17.753,9.488c-0.542-4.006-1.199-7.972-2.08-11.862c1.918-0.967,3.834-1.948,5.745-2.953C414.117,205.296,420.674,212.564,409.354,220.575z"/>
                                    <circle class="st0" cx="213.445" cy="269.682" r="12.866"/>
                                    <circle class="st0" cx="298.56" cy="269.682" r="12.866"/>
                                    <path class="st0" d="M163.07,384.226c-78.319,16.484-74.198,82.432-74.198,82.432C142.456,507.879,244.961,512,244.961,512V410.384C185.207,407.809,163.07,384.226,163.07,384.226z"/>
                                    <path class="st0" d="M348.919,384.226c0,0-22.137,23.583-81.89,26.158V512c0,0,102.505-4.121,156.088-45.342C423.117,466.658,427.238,400.711,348.919,384.226z"/>
                                </g>
                            </svg>
                        </div>
                    } id="desplegable">
                        <NavDropdown.Item onClick={collapsenav} as={NavLink} to="/usuari">Les meves dades</NavDropdown.Item>
                        <NavDropdown.Item onClick={toggleModalLogout}>Tancar sessió</NavDropdown.Item>
                    </NavDropdown>
                </div>
                )}
            </Nav>
            </Navbar.Collapse>
            </Navbar>
            <Container >
                <Outlet />
            </Container>
            <Logout isOpen={modalLogoutOpen} onClose={toggleModalLogout} usuari_id={id} /> 
            {/* Footer */}
            <footer className='footer'>
                <Container>
                    <div className="footer-container">
                        <div className="drets-footer">
                            <p className="mb-0">&copy; {new Date().getFullYear()} AeroGuardias - Tots els drets reservats.</p>
                        </div>
                        <div className='links-container'>
                            <Link to="/condicions" className="footer-link">Condicions d'ús</Link>
                            <Link to="/privacitat" className="footer-link">Política de privacitat</Link>  
                        </div>
                        <div className="contacte-footer">
                            <p className="mb-0 ml-3">Contacte: info@aeroguardias.es</p>
                        </div>
                    </div>
                </Container>
            </footer>
        </>
    );
}
