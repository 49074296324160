import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { storage } from '../../utils/storage';

export default function NotesBombero(props) {
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editedNote, setEditedNote] = useState('');
    const [notadata, setNotadata] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false); // State to control the display of the modal
    const [noteToDeleteId, setNoteToDeleteId] = useState(null); // State to store the id of the note to delete
    const navigate = useNavigate();
    const token = props.token; 
    const id = props.usuari_id;

    useEffect(() => {
        if (!id) {
            return; // Si no hay ID, no hacemos nada (esto evitará ejecutar fetchNotes innecesariamente)
        }
        fetchNotes();
    }, [id, token]); // Añadimos id y token como dependencias para re-ejecutar este efecto si cambian


    const fetchNotes = async () => {
        setLoading(true); // Iniciar el indicador de carga

        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/notes/usuari/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setNotes(data.data);
        } catch (error) {
            console.error('Error en descargar las notas:', error);
        } finally {
            setLoading(false); // Finalizar el indicador de carga
        }
    };

    // Obtener la fecha actual en formato YYYY-MM-DD
    const currentDate = new Date().toISOString().split('T')[0];

    // Filtrar las notas para incluir solo las que tienen la misma fecha que el día actual
    const currentDayNotes = notes && notes.filter(note => note.data === currentDate);
    const otherDayNotes = notes && notes.filter(note => note.data !== currentDate);

    const handleEditNote = (noteId, currentNote, currentData) => {
        setEditingNoteId(noteId);
        setEditedNote(currentNote);
        setNotadata(currentData);
    };
    
    if (loading) {
        return <div className="spinner-excels">
            <Spinner animation="border" />
        </div>;
    }

    const saveEditedNote = async (noteId) => {
        const noteData = {
            nota: editedNote,
            data: notadata ? notadata : null // Asegura que si no hay fecha, se envíe null
        };
        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/notes/${noteId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(noteData)
            });
            const data = await response.json();
            // Refrescar las notas después de editar
            setEditingNoteId(null);
            setEditedNote('');
            setNotadata('');
            storage.remove('notesCalendari');
            window.location.reload();
        } catch (error) {
            console.error('Error en modificar la nota:', error);
        }
    };

    const borrarnota = async (noteId) => {
        try {
            // Store the id of the note to delete and show the confirmation modal
            setNoteToDeleteId(noteId);
            setShowConfirmModal(true);
        } catch (error) {
            console.error('Error en borrar la nota:', error);
        }
    };

    // Function to confirm note deletion
    const confirmDelete = async () => {
        try {
            const response = await fetch(`https://aeroguardias.es/aeroguardiasapi/public/api/notes/${noteToDeleteId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            // If deletion is successful, update the list of notes
            if (response.ok) {
                storage.remove('notesCalendari');
                window.location.reload();
            } else {
                console.error('Error al borrar la nota:', response.statusText);
            }
            // Close the confirmation modal
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Error en borrar la nota:', error);
        }
    };

    return (
        <div className='notes'>
            {currentDayNotes && currentDayNotes.length > 0 ? (
                <div className='notessec'>
                    <h2>Notes d'avui:</h2>
                    <hr />
                    <div className='grupnotes'>
                        {currentDayNotes && currentDayNotes.map(note => (
                            <div key={note.id} className='notes-container'>
                            <div>
                                {editingNoteId === note.id ? (
                                    <div className='nota'>
                                        <div className={note.data ? 'nota-data' : 'nota-sense-data'}>
                                            <h5>{note.data ? `Nota per dia: ${note.data}` : 'Nota'}</h5>
                                        </div>
                                        <div className='editar-nota'>
                                            <p>Text de la nota:</p>
                                            <textarea
                                                value={editedNote}
                                                onChange={(e) => setEditedNote(e.target.value)}
                                                className="form-control mb-2"
                                                rows="3"
                                            />
                                            <p>Data (Opcional):</p>
                                            <input
                                                type="date"
                                                value={notadata}
                                                onChange={(e) => setNotadata(e.target.value)}
                                                className="form-control mb-2"
                                            />
                                            <div className='botons-editar-nota'>
                                                <Button variant="secondary" onClick={() => saveEditedNote(note.id)}>Guardar</Button>
                                                <Button variant="danger" onClick={() => setEditingNoteId(null)}>Cancelar</Button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='nota'>
                                        <div className={note.data ? 'nota-data' : 'nota-sense-data'}>
                                            <h5>{note.data ? `Nota per dia: ${note.data}` : 'Nota'}</h5>
                                        </div>
                                        <div className='nota-text'>
                                            <p>{note.nota}</p>
                                        </div>
                                        <div className='accions'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16" onClick={() => handleEditNote(note.id, note.nota, note.data)}>
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16" onClick={() => borrarnota(note.id)}>
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                            </svg>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            ) : (
                // <div id='infonotes' className="alert alert-success" role="alert">
                <div id='infonotes' className="missatge-notes">
                    <p>No hi ha cap nota per avui 😃</p>
                </div>
            )}

            {otherDayNotes && otherDayNotes.length > 0 && (
                <div className="notessec">
                    <hr />
                    <h2>Altres notes:</h2>
                    <hr />
                    <div className='grupnotes'>
                        {otherDayNotes && otherDayNotes.map(note => (
                            <div key={note.id} className='notes-container'>
                                <div>
                                    {editingNoteId === note.id ? (
                                        <div className='nota'>
                                            <div className={note.data ? 'nota-data' : 'nota-sense-data'}>
                                                <h5>{note.data ? `Nota per dia: ${note.data}` : 'Nota'}</h5>
                                            </div>
                                            <div className='editar-nota'>
                                                <p>Text de la nota:</p>
                                                <textarea
                                                    value={editedNote}
                                                    onChange={(e) => setEditedNote(e.target.value)}
                                                    className="form-control mb-2"
                                                    rows="3"
                                                />
                                                <p>Data (Opcional):</p>
                                                <input
                                                    type="date"
                                                    value={notadata}
                                                    onChange={(e) => setNotadata(e.target.value)}
                                                    className="form-control mb-2"
                                                />
                                                <div className='botons-editar-nota'>
                                                    <Button variant="secondary" onClick={() => saveEditedNote(note.id)}>Guardar</Button>
                                                    <Button variant="danger" onClick={() => setEditingNoteId(null)}>Cancelar</Button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='nota'>
                                            <div className={note.data ? 'nota-data' : 'nota-sense-data'}>
                                                <h5>{note.data ? `Nota per dia: ${note.data}` : 'Nota'}</h5>
                                            </div>
                                            <div className='nota-text'>
                                                <p>{note.nota}</p>
                                            </div>
                                            <div className='accions'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16" onClick={() => handleEditNote(note.id, note.nota, note.data)}>
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16" onClick={() => borrarnota(note.id)}>
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {/* Confirmation modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Esborrar la nota</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant='info'>
                        Segur que vols esborrar la nota?
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Borrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
